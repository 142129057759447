<template>
  <div class="score-centre">
    <div class="header d-flex align-self-center mb-3">
      <h1 class="p-0 mb-0 align-self-center mr-3">Score Centre</h1>
    </div>
    <h6>
      <b-badge variant="light" class="w-100">Overall Result</b-badge>
    </h6>
    <overall-result-chart :loading="loadingOverallCharts"></overall-result-chart>
    <h6>
      <b-badge variant="light" class="w-100">Weekly Result</b-badge>
    </h6>
    <weekly-result-chart :loading="loadingWeeklyCharts"></weekly-result-chart>
    <h6>
      <b-badge variant="light" class="w-100">Results Summary</b-badge>
    </h6>
    <round-break-down-table :loadingWeeks="loadingTeamWeeks"></round-break-down-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ScaleLoader from 'vue-spinner/src/ScaleLoader'
import BaseChart from '@/components/ScoreCentre/BaseChart.vue'
import OverallResultChart from '@/components/ScoreCentre/OverallResultChart.vue'
import WeeklyResultChart from '@/components/ScoreCentre/WeeklyResultChart.vue'
import RoundBreakDownTable from '@/components/ScoreCentre/RoundBreakDownTable.vue'
import {
  teamWeekProcessedDataFields,
  weeklyProcessedChartOptions,
  teamWeekProfitDataFields,
  teamWeekPassDataFields,
  weeklyPassedChartOptions,
  weeklyProfitabilityChartOptions
} from '@/components/Dashboard/config'
import { managementMixin } from '@/mixins/managementMixin'
export default {
  components: {
    ScaleLoader,
    BaseChart,
    OverallResultChart,
    WeeklyResultChart,
    RoundBreakDownTable
  },
  mixins: [managementMixin],
  data() {
    return {
      teamWeekProcessedDataFields,
      weeklyProcessedChartOptions,
      teamWeekProfitDataFields,
      teamWeekPassDataFields,
      weeklyPassedChartOptions,
      weeklyProfitabilityChartOptions,
      loader: {
        color: '#2d7281'
      },
      loadingTeamWeeks: false,
      loadingOverallCharts: false,
      loadingWeeklyCharts: false,
      loadingRoundBreakdownTable: false
    }
  },
  methods: {
    ...mapActions([
      'fetchAllTeamWeeks',
      'fetchOverallResultsCharts',
      'fetchWeeklyResultsCharts',
      'fetchAllProcessedScores',
      'fetchRoundBreakdownTable'
    ]),
    async getTeamWeeks() {
      let self = this
      let teamId = self.currentUser.teamId ? self.currentUser.teamId : 1
      self.loadingTeamWeeks = true
      await self.fetchAllTeamWeeks(teamId)
      self.loadingTeamWeeks = false
    },
    async getOverallResultsCharts() {
      let self = this
      await self.fetchOverallResultsCharts()
      self.loadingOverallCharts = false
    },
    async getWeeklyResultsCharts() {
      let self = this
      await self.fetchWeeklyResultsCharts()
      self.loadingWeeklyCharts = false
    },
    async getRoundBreakdownTable() {
      let self = this
      await self.fetchRoundBreakdownTable()
      self.loadingRoundBreakdownTable = false
    },
    async getAllData() {
      let self = this
      self.loadingOverallCharts = true
      self.loadingWeeklyCharts = true
      self.loadingRoundBreakdownTable = true
      await self.getOverallResultsCharts()
      await self.getWeeklyResultsCharts()
      // await self.getRoundBreakdownTable()
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'allTeamWeeks',
      'allTeamProcessedScores',
      'overallProfitability',
      'overallVolume',
      'overallAccuracy',
      'weeklyProfitability',
      'weeklyVolume',
      'weeklyAccuracy',
      'roundBreakdown'
    ])
  },
  async created() {
    await this.getTeamWeeks()
    await this.getAllData()
  }
}
</script>

<style lang="scss" scoped>
.stats-table {
  height: 400px;
}

.v-spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100px;
  text-align: center;
  opacity: 0.8;
}

.table.b-table > thead > tr > .table-b-table-default {
  border: none;
}

.table thead th {
  border: none;
}
</style>
