var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "score-centre" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "h6",
        [
          _c("b-badge", { staticClass: "w-100", attrs: { variant: "light" } }, [
            _vm._v("Overall Result"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("overall-result-chart", {
        attrs: { loading: _vm.loadingOverallCharts },
      }),
      _vm._v(" "),
      _c(
        "h6",
        [
          _c("b-badge", { staticClass: "w-100", attrs: { variant: "light" } }, [
            _vm._v("Weekly Result"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("weekly-result-chart", {
        attrs: { loading: _vm.loadingWeeklyCharts },
      }),
      _vm._v(" "),
      _c(
        "h6",
        [
          _c("b-badge", { staticClass: "w-100", attrs: { variant: "light" } }, [
            _vm._v("Results Summary"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("round-break-down-table", {
        attrs: { loadingWeeks: _vm.loadingTeamWeeks },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header d-flex align-self-center mb-3" }, [
      _c("h1", { staticClass: "p-0 mb-0 align-self-center mr-3" }, [
        _vm._v("Score Centre"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }