var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-financials-control" },
    [
      _c(
        "b-card",
        {
          staticClass: "game-financials-card",
          attrs: { "body-class": "game-financials-card-content" },
        },
        [
          _c("div", { staticClass: "header d-flex mb-4" }, [
            _c("h4", { staticClass: "m-0 animate__animated animate__fadeIn" }, [
              _vm._v("Game Financials"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "w-100 d-flex flex-column flex-lg-row py-2 game-financials-header",
            },
            [
              _c(
                "p",
                { staticClass: "mb-0 w-20 w-sm-100 align-middle text-center" },
                [_vm._v("Description")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "mb-0 w-20 w-sm-100 align-middle text-center",
                  on: { click: _vm.orderGameFinancials },
                },
                [
                  _c("span", [_vm._v("Amount")]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "fa ml-1",
                    class: _vm.orderedGameFinancials
                      ? "fa-caret-up"
                      : "fa-caret-down",
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "mb-0 w-20 w-sm-100 align-middle text-center" },
                [_vm._v("Type")]
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "mb-0 w-15 w-sm-100 align-middle text-center" },
                [_vm._v("Week")]
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "mb-0 w-15 w-sm-100 align-middle text-center" },
                [_vm._v("Day")]
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "mb-0 w-10 w-sm-100 align-middle text-center" },
                [_vm._v("Actions")]
              ),
            ]
          ),
          _vm._v(" "),
          _c("game-financials-control-form", {
            key: `new-financial-form-${_vm.reloadedForm}`,
            staticClass: "my-3",
            attrs: { loading: _vm.loading, processing: _vm.processing },
            on: { itemAdded: _vm.onNewFinancialAdd },
            model: {
              value: _vm.newFinancial,
              callback: function ($$v) {
                _vm.newFinancial = $$v
              },
              expression: "newFinancial",
            },
          }),
          _vm._v(" "),
          _c(
            "b-overlay",
            {
              attrs: {
                show: _vm.loadingGameFinancials,
                rounded: "sm",
                "bg-color": "white",
              },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function () {
                    return [_c("running-loader", { attrs: { loading: "" } })]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "vue-perfect-scrollbar",
                {
                  staticClass: "scroll",
                  attrs: {
                    settings: {
                      suppressScrollX: true,
                      wheelPropagation: false,
                    },
                  },
                },
                _vm._l(_vm.sortGameFinancials, function (gameFinancial, index) {
                  return _c(
                    "div",
                    { key: `fin-${index}`, staticClass: "mb-2" },
                    [
                      _vm.editedItemIds.includes(gameFinancial.id)
                        ? _c("game-financials-control-form", {
                            key: "new-financial-form",
                            staticClass: "my-3",
                            attrs: { edit: "" },
                            on: {
                              itemEdited: function ($event) {
                                return _vm.onItemEditConfirm(
                                  _vm.sortGameFinancials[index]
                                )
                              },
                              itemCancelled: function ($event) {
                                return _vm.onItemEditCancel(
                                  _vm.sortGameFinancials[index]
                                )
                              },
                            },
                            model: {
                              value: _vm.sortGameFinancials[index],
                              callback: function ($$v) {
                                _vm.$set(_vm.sortGameFinancials, index, $$v)
                              },
                              expression: "sortGameFinancials[index]",
                            },
                          })
                        : _c("game-financials-item", {
                            key: `fin-item-${index}-${_vm.sortGameFinancials[index].status}`,
                            staticClass:
                              "d-flex py-3 financial-item animate__animated animate__fadeIn",
                            on: {
                              edited: function ($event) {
                                return _vm.onItemEdit(
                                  _vm.sortGameFinancials[index]
                                )
                              },
                              deleted: function ($event) {
                                return _vm.onItemDelete(
                                  _vm.sortGameFinancials[index]
                                )
                              },
                            },
                            model: {
                              value: _vm.sortGameFinancials[index],
                              callback: function ($$v) {
                                _vm.$set(_vm.sortGameFinancials, index, $$v)
                              },
                              expression: "sortGameFinancials[index]",
                            },
                          }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }