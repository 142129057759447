var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-toggle-button" }, [
    _c(
      "div",
      { staticClass: "switch-button", style: `width:${_vm.buttonSize}px` },
      [
        _c("span", {
          staticClass: "active btn-shadow",
          style: _vm.selectedToggleId === 1 ? "left:0%" : "left:50%",
        }),
        _vm._v(" "),
        _vm._l(_vm.toggleItems, function (item, index) {
          return _c(
            "button",
            {
              key: `item-${index}`,
              class: [
                item.class,
                item.id === _vm.selectedToggleId ? "active-case" : "",
                "font-bold",
              ],
              attrs: { disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  return _vm.onToggleSwitch(item.id)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(item.title) + "\n    ")]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }