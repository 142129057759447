var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-colxx",
        {
          staticClass: "mb-4",
          attrs: { xl: "4", lg: "12", sm: "12", md: "12" },
        },
        [
          _c(
            "b-card",
            { staticClass: "dashboard-link-list", attrs: { "no-body": "" } },
            [
              _c("h5", { staticClass: "m-4" }, [
                _vm._v("Overall Profitability"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mx-3 my-0" },
                [
                  _c("base-chart", {
                    key: `profitability-${_vm.reloadChart}-${_vm.reloadProfitabilityChart}`,
                    attrs: {
                      loading: _vm.loading,
                      data: _vm.profitabilityChartData,
                      options: _vm.profitabilityChartOptions,
                      shadow: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml-5 mr-4" },
                [
                  _vm.profitabilityChartDataSet &&
                  _vm.profitabilityChartDataSet.length > 0
                    ? _c("overall-result-chart-legend", {
                        key: "profit-chart-legend",
                        attrs: {
                          formatType: 2,
                          options: _vm.profitabilityChartDataSet,
                        },
                        on: { optionClicked: _vm.onProfitabilityOptionClick },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-colxx",
        {
          staticClass: "mb-4",
          attrs: { xl: "4", lg: "12", sm: "12", md: "12" },
        },
        [
          _c(
            "b-card",
            { staticClass: "dashboard-link-list", attrs: { "no-body": "" } },
            [
              _c("h5", { staticClass: "m-4" }, [_vm._v("Overall Volume")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mx-3 my-0" },
                [
                  _c("base-chart", {
                    key: `volume-${_vm.reloadChart}-${_vm.reloadVolumeChart}`,
                    attrs: {
                      loading: _vm.loading,
                      data: _vm.volumeChartData,
                      options: _vm.volumeChartOptions,
                      shadow: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml-5 mr-4" },
                [
                  _vm.volumeChartDataSet && _vm.volumeChartDataSet.length > 0
                    ? _c("overall-result-chart-legend", {
                        key: "volume-chart-legend",
                        attrs: {
                          formatType: 1,
                          options: _vm.volumeChartDataSet,
                        },
                        on: { optionClicked: _vm.onVolumeOptionClick },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-colxx",
        {
          staticClass: "mb-4",
          attrs: { xl: "4", lg: "12", sm: "12", md: "12" },
        },
        [
          _c(
            "b-card",
            { staticClass: "dashboard-link-list", attrs: { "no-body": "" } },
            [
              _c("h5", { staticClass: "m-4" }, [_vm._v("Overall Accuracy")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mx-3 my-0" },
                [
                  _c("base-chart", {
                    key: `accuracy-${_vm.reloadChart}-${_vm.reloadAccuracyChart}`,
                    attrs: {
                      loading: _vm.loading,
                      data: _vm.accuracyChartData,
                      options: _vm.accuracyChartOptions,
                      shadow: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml-5 mr-4" },
                [
                  _vm.accuracyChartDataSet &&
                  _vm.accuracyChartDataSet.length > 0
                    ? _c("overall-result-chart-legend", {
                        key: "accuracy-chart-legend",
                        attrs: {
                          formatType: 3,
                          options: _vm.accuracyChartDataSet,
                        },
                        on: { optionClicked: _vm.onAccuracyOptionClick },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }