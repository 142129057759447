var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      attrs: { show: _vm.loading, rounded: "sm", opacity: _vm.opacity },
      scopedSlots: _vm._u([
        {
          key: "overlay",
          fn: function () {
            return [
              _c("pulse-loader", {
                staticClass: "align-self-center",
                attrs: {
                  loading: _vm.loading,
                  color: _vm.fill,
                  size: _vm.loaderSize,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._t("content")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }