var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "team-week-day-control" },
    [
      _c(
        "b-overlay",
        {
          attrs: { show: _vm.loading, rounded: "sm", "bg-color": "white" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [_c("running-loader", { attrs: { loading: "" } })]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.loading
            ? _c(
                "div",
                { staticClass: "day-control mx-auto" },
                [
                  _c(
                    "b-row",
                    { staticClass: "text-center mx-auto" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "p-0", attrs: { cols: "2" } },
                        [
                          _c("b-avatar", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content: _vm.activeTeamWeek.week_name,
                                  placement: "bottom",
                                  classes: ["light"],
                                  offset: 10,
                                },
                                expression:
                                  "{\n              content: activeTeamWeek.week_name,\n              placement: 'bottom',\n              classes: ['light'],\n              offset: 10\n            }",
                              },
                            ],
                            staticClass: "btn-shadow",
                            attrs: {
                              size: "3.5em",
                              text: _vm.getNameInitials(
                                _vm.activeTeamWeek.week_name
                              ),
                              variant: "info",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(
                        _vm.activeTeamWeek.team_week_days,
                        function (day, index) {
                          return _c(
                            "b-col",
                            {
                              key: `day-${index}`,
                              staticClass: "p-0",
                              attrs: { cols: "2" },
                            },
                            [
                              _c("b-avatar", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: {
                                      content: day.day_name,
                                      placement: "bottom",
                                      classes: ["light"],
                                      offset: 10,
                                    },
                                    expression:
                                      "{\n              content: day.day_name,\n              placement: 'bottom',\n              classes: ['light'],\n              offset: 10\n            }",
                                  },
                                ],
                                staticClass: "btn-shadow",
                                class: {
                                  "animate__animated animate__fadeIn":
                                    day.status_id === 3,
                                },
                                attrs: {
                                  size: "3.5em",
                                  text: _vm.getNameInitials(day.day_name),
                                  variant: "new",
                                  disabled: day.status_id !== 3,
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "text-center my-4 align-self-center",
                      attrs: { id: "clock" },
                    },
                    [
                      _c("timer", {
                        staticClass: "time",
                        class: {
                          pending:
                            _vm.loadingGame || _vm.currentGame.status === 2,
                        },
                        attrs: {
                          time: _vm.prettyTime,
                          warning: _vm.runningOutWarning,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-center my-2" },
                    [
                      _c("overlay-loader", {
                        staticClass: "w-50 mx-2",
                        attrs: { loading: _vm.loadingGame },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "btn-shadow w-100 d-flex action-button",
                                      attrs: {
                                        size: "md",
                                        variant:
                                          !_vm.nextTeamWeek || !_vm.isFriday
                                            ? "light"
                                            : "info",
                                        pill: "",
                                        disabled:
                                          !_vm.nextTeamWeek || !_vm.isFriday,
                                      },
                                      on: { click: _vm.onWeekClick },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "align-self-center" },
                                        [_vm._v("Next Week")]
                                      ),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "simple-icon-action-redo align-self-center ml-auto",
                                      }),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2347945927
                        ),
                      }),
                      _vm._v(" "),
                      _c("overlay-loader", {
                        staticClass: "w-50 mx-2",
                        attrs: { loading: _vm.loadingGame },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "btn-shadow w-100 d-flex action-button",
                                      attrs: {
                                        size: "md",
                                        variant: "danger",
                                        pill: "",
                                        disabled: !_vm.nextTeamWeekDay,
                                      },
                                      on: { click: _vm.onDayClick },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "align-self-center" },
                                        [_vm._v("Next Day")]
                                      ),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "simple-icon-action-redo align-self-center ml-auto",
                                      }),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3436370642
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-center my-3" },
                    [
                      _c("overlay-loader", {
                        staticClass: "w-100 mx-2",
                        attrs: { loading: _vm.loadingGame },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "btn-shadow w-100 d-flex action-button",
                                      class: {
                                        disabled:
                                          _vm.gameTrigger.disabled ||
                                          !_vm.hasWorkAllocation ||
                                          _vm.loadingWorkStatus,
                                      },
                                      attrs: {
                                        size: "md",
                                        variant:
                                          _vm.hasWorkAllocation &&
                                          !_vm.loadingWorkStatus
                                            ? "warning"
                                            : "light",
                                        pill: "",
                                        disabled:
                                          _vm.gameTrigger.disabled ||
                                          !_vm.hasWorkAllocation ||
                                          _vm.loadingWorkStatus,
                                      },
                                      on: { click: _vm.onGameClick },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "align-self-center" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.gameTrigger.triggerName)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "simple-icon-rocket align-self-center ml-auto",
                                      }),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          530832230
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("speech-bubble", {
                    staticClass: "mx-3 mt-5",
                    attrs: { show: !_vm.hasWorkAllocation },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n          Work must be assigned to team members before " +
                                  _vm._s(_vm.activeTeamWeek.week_name) +
                                  " can\n          commence.\n        "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2736194666
                    ),
                  }),
                  _vm._v(" "),
                  _c("speech-bubble", {
                    staticClass: "mx-3 mt-5",
                    attrs: {
                      show:
                        _vm.hasWorkAllocation && _vm.gameTrigger.status === 0,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.activeTeamWeekDay.day_name) +
                                  " is ready to start. "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3225713958
                    ),
                  }),
                  _vm._v(" "),
                  _c("speech-bubble", {
                    staticClass: "mx-3 mt-5",
                    attrs: {
                      show:
                        _vm.hasWorkAllocation && _vm.gameTrigger.status === 1,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n          Waiting for " +
                                  _vm._s(_vm.activeTeamWeekDay.day_name) +
                                  " to finish.\n          " +
                                  _vm._s(_vm.activeTeamWeekDay.day_name) +
                                  " will be completed in " +
                                  _vm._s(_vm.time) +
                                  "s.\n        "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3002876326
                    ),
                  }),
                  _vm._v(" "),
                  _c("speech-bubble", {
                    staticClass: "mx-3 mt-5",
                    attrs: {
                      show:
                        _vm.hasWorkAllocation && _vm.gameTrigger.status === 2,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.activeTeamWeekDay.day_name) +
                                  " has now finished. To proceed to the next round please\n          click next day or week.\n        "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1257345250
                    ),
                  }),
                  _vm._v(" "),
                  _c("speech-bubble", {
                    staticClass: "mx-3 mt-5",
                    attrs: {
                      show:
                        _vm.hasWorkAllocation && _vm.gameTrigger.status === -1,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _c("pulse-loader", {
                                attrs: {
                                  loading:
                                    _vm.hasWorkAllocation &&
                                    _vm.gameTrigger.status === -1,
                                  color: _vm.fill,
                                  size: "5px",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2383065156
                    ),
                  }),
                ],
                1
              )
            : _c("div", { staticClass: "control-loader" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "control-confirmation-modal",
          attrs: {
            id: "control-confirmation",
            "hide-header": "",
            "hide-footer": "",
          },
          on: {
            hide: function ($event) {
              return _vm.onModalTrigger(false)
            },
          },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c(
            "b-form",
            {
              staticClass: "text-center",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onConfirmClick.apply(null, arguments)
                },
              },
            },
            [
              _c("img", {
                staticClass: "modal-img my-4",
                attrs: { src: _vm.modalConent.img, alt: "" },
              }),
              _vm._v(" "),
              _c("h3", { staticClass: "p-2" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.modalConent.title) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.modalConent.subtitle) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("processing-button", {
                    staticClass: "align-self-center btn-shadow my-2",
                    attrs: {
                      variant: "warning",
                      size: "lg",
                      label: "Confirm",
                      processing: _vm.processing,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.allTeamWeekDays
        ? _c(
            "b-modal",
            {
              ref: "welcome-modal",
              attrs: {
                id: "welcome-modal",
                "hide-header": "",
                "hide-footer": "",
                "body-class": "welcome-modal-body text-center",
              },
              on: {
                hide: function ($event) {
                  return _vm.onWelcomeModalTrigger(false)
                },
              },
              model: {
                value: _vm.showWelcomeModal,
                callback: function ($$v) {
                  _vm.showWelcomeModal = $$v
                },
                expression: "showWelcomeModal",
              },
            },
            [
              _c("img", {
                staticClass: "modal-img my-4",
                attrs: {
                  src: "/assets/img/svg/controls/party_outline_new.svg",
                  alt: "Welcome New Week Day",
                },
              }),
              _vm._v(" "),
              _c("h2", [_vm._v("Welcome to")]),
              _vm._v(" "),
              _c("h4", { staticClass: "content text-uppercase" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.activeTeamWeek.week_name) +
                    " - " +
                    _vm._s(_vm.activeTeamWeekDay.day_name) +
                    "\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }