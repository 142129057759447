var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      attrs: {
        variant: "empty",
        "toggle-class": "p-0",
        "menu-class": "a-shadow-sm",
        "no-caret": "",
      },
    },
    [
      _c("template", { slot: "button-content" }, [
        _c("div", { staticClass: "d-flex" }, [
          _vm.badge
            ? _c(
                "p",
                { staticClass: "p-0 m-0 self-align-center" },
                [
                  _c("b-badge", { attrs: { variant: _vm.badgeVariant } }, [
                    _c("span", [_vm._v(_vm._s(_vm.selected[_vm.itemValue]))]),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "fa fa-angle-down self-align-center ml-1",
                    }),
                  ]),
                ],
                1
              )
            : _c("span", { staticClass: "self-align-center" }, [
                _vm._v(_vm._s(_vm.selected[_vm.itemValue])),
              ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.options, function (item, index) {
        return _c(
          "b-dropdown-item",
          {
            key: `item-${index}`,
            attrs: { active: _vm.setItemActive(index) },
            on: {
              click: function ($event) {
                return _vm.onItemClick(item)
              },
            },
          },
          [_c("span", [_vm._v(_vm._s(item[_vm.itemValue]))])]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }