var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-body game-financials-control-slide-two" },
    [
      _c(
        "b-form",
        {
          ref: "addFinancialForm",
          staticClass: "mb-4",
          attrs: { inline: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.addNewFinancial.apply(null, arguments)
            },
          },
        },
        [
          _c("b-table", {
            ref: "financialTable",
            staticClass: "game-financials-table",
            attrs: {
              responsive: "",
              hover: "",
              items: _vm.financials,
              fields: _vm.fields,
              "details-td-class": "tdClass",
              "sort-by": "id",
              "tbody-tr-class": "body-tr",
            },
            scopedSlots: _vm._u([
              {
                key: "top-row",
                fn: function () {
                  return [
                    _c(
                      "b-td",
                      [
                        _c(
                          "label",
                          {
                            staticClass: "sr-only",
                            attrs: { for: "inline-form-input-description" },
                          },
                          [_vm._v("Description")]
                        ),
                        _vm._v(" "),
                        _c("b-form-input", {
                          staticClass: "mb-2 mb-sm-0 sm-input w-100",
                          attrs: {
                            required: "",
                            id: "inline-form-input-description",
                            placeholder: "Description",
                          },
                          model: {
                            value: _vm.newFinancial.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.newFinancial, "description", $$v)
                            },
                            expression: "newFinancial.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-td",
                      { staticClass: "th-amount" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "sr-only",
                            attrs: { for: "inline-form-input-amount" },
                          },
                          [_vm._v("Amount")]
                        ),
                        _vm._v(" "),
                        _c("b-form-input", {
                          staticClass: "mb-2 mb-sm-0 sm-input w-100",
                          attrs: {
                            required: "",
                            type: "number",
                            id: "inline-form-input-amount",
                            placeholder: "Amount",
                          },
                          model: {
                            value: _vm.newFinancial.amount,
                            callback: function ($$v) {
                              _vm.$set(_vm.newFinancial, "amount", $$v)
                            },
                            expression: "newFinancial.amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-td",
                      [
                        _c(
                          "label",
                          {
                            staticClass: "sr-only",
                            attrs: { for: "inline-form-input-type" },
                          },
                          [_vm._v("Type")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-dropdown",
                          {
                            attrs: {
                              variant: "empty",
                              "toggle-class": "p-0",
                              "menu-class": "a-shadow-sm",
                            },
                          },
                          [
                            _c("template", { slot: "button-content" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.newFinancial.type)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.financialTypes, function (item, index) {
                              return _c(
                                "b-dropdown-item",
                                {
                                  key: `type-${index}`,
                                  attrs: {
                                    active: _vm.newFinancial.type == item,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.newFinancial.type = item
                                    },
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(item))])]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-td",
                      { staticClass: "select" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "sr-only",
                            attrs: { for: "inline-form-input-week" },
                          },
                          [_vm._v("Week")]
                        ),
                        _vm._v(" "),
                        _c("b-form-select", {
                          attrs: {
                            size: "sm",
                            name: "week",
                            options: _vm.availableWeeks,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.selectWeek(_vm.newFinancial)
                            },
                          },
                          model: {
                            value: _vm.newFinancial.week,
                            callback: function ($$v) {
                              _vm.$set(_vm.newFinancial, "week", $$v)
                            },
                            expression: "newFinancial.week",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-td",
                      { staticClass: "select" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "sr-only",
                            attrs: { for: "inline-form-input-Day" },
                          },
                          [_vm._v("Day")]
                        ),
                        _vm._v(" "),
                        _c("b-form-select", {
                          staticClass: "day",
                          attrs: {
                            size: "sm",
                            options: _vm.availableDays(_vm.newFinancial.week),
                          },
                          model: {
                            value: _vm.newFinancial.day,
                            callback: function ($$v) {
                              _vm.$set(_vm.newFinancial, "day", $$v)
                            },
                            expression: "newFinancial.day",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("b-td", [
                      _c("div", { staticClass: "text-center" }, [
                        _c("i", {
                          staticClass: "simple-icon-plus text-primary",
                          on: {
                            click: function ($event) {
                              return _vm.addFinancialForm()
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(description)",
                fn: function (row) {
                  return [
                    row.item.editing && row.field.editable
                      ? _c("b-form-input", {
                          staticClass: "mb-2 mb-sm-0 sm-input",
                          attrs: {
                            required: "",
                            size: "sm",
                            placeholder: "Description",
                          },
                          model: {
                            value: row.item.description,
                            callback: function ($$v) {
                              _vm.$set(row.item, "description", $$v)
                            },
                            expression: "row.item.description",
                          },
                        })
                      : _c(
                          "div",
                          {
                            staticClass: "col",
                            class: row.item.status == "delete" ? "disable" : "",
                          },
                          [
                            row.item.status == "add"
                              ? _c("span", { staticClass: "new_span" }, [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-check-circle text-success",
                                  }),
                                ])
                              : _vm._e(),
                            _vm._v(
                              "\n          " +
                                _vm._s(row.item.description) +
                                "\n        "
                            ),
                          ]
                        ),
                  ]
                },
              },
              {
                key: "cell(amount)",
                fn: function (row) {
                  return [
                    row.item.editing && row.field.editable
                      ? _c("b-form-input", {
                          ref: "amount",
                          staticClass: "mb-2 mb-sm-0 sm-input",
                          attrs: {
                            required: "",
                            type: "number",
                            size: "sm",
                            placeholder: "Amount",
                          },
                          model: {
                            value: row.item.amount,
                            callback: function ($$v) {
                              _vm.$set(row.item, "amount", $$v)
                            },
                            expression: "row.item.amount",
                          },
                        })
                      : _c(
                          "span",
                          {
                            class: row.item.status == "delete" ? "disable" : "",
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("formatAmount")(row.item.amount)
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                  ]
                },
              },
              {
                key: "cell(type)",
                fn: function (row) {
                  return [
                    row.item.editing && row.field.editable
                      ? _c(
                          "b-dropdown",
                          {
                            attrs: {
                              variant: "empty",
                              "toggle-class": "p-0",
                              "menu-class": "a-shadow-sm",
                            },
                          },
                          [
                            _c("template", { slot: "button-content" }, [
                              _c("span", [_vm._v(_vm._s(row.item.type))]),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.financialTypes, function (item, index) {
                              return _c(
                                "b-dropdown-item",
                                {
                                  key: `type-${index}`,
                                  attrs: { active: row.item.type == item },
                                  on: {
                                    click: function ($event) {
                                      row.item.type = item
                                    },
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(item))])]
                              )
                            }),
                          ],
                          2
                        )
                      : _c(
                          "span",
                          {
                            class: row.item.status == "delete" ? "disable" : "",
                          },
                          [
                            _c(
                              "b-badge",
                              {
                                attrs: {
                                  variant:
                                    row.item.status == "delete"
                                      ? "light"
                                      : row.item.type.toLowerCase() === "credit"
                                      ? "info"
                                      : "warning",
                                  pill: "",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.item.type) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
              {
                key: "cell(day)",
                fn: function (row) {
                  return [
                    row.item.editing && row.field.editable
                      ? _c("b-form-select", {
                          staticClass: "day",
                          attrs: {
                            name: "day",
                            options: _vm.availableDays(row.item.week),
                          },
                          model: {
                            value: row.item.day,
                            callback: function ($$v) {
                              _vm.$set(row.item, "day", $$v)
                            },
                            expression: "row.item.day",
                          },
                        })
                      : _c(
                          "span",
                          {
                            class:
                              row.item.status == "delete"
                                ? ["disable", "disable-day"]
                                : "",
                          },
                          [
                            _c(
                              "b-badge",
                              {
                                attrs: {
                                  variant:
                                    row.item.status == "delete"
                                      ? "light"
                                      : "outline-new",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.getDay(row.item.day)) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
              {
                key: "cell(week)",
                fn: function (row) {
                  return [
                    row.item.editing && row.field.editable
                      ? _c("b-form-select", {
                          attrs: { name: "week", options: _vm.availableWeeks },
                          on: {
                            change: function ($event) {
                              return _vm.selectWeek(row.item)
                            },
                          },
                          model: {
                            value: row.item.week,
                            callback: function ($$v) {
                              _vm.$set(row.item, "week", $$v)
                            },
                            expression: "row.item.week",
                          },
                        })
                      : _c(
                          "span",
                          {
                            class:
                              row.item.status == "delete"
                                ? ["disable", "disable-week"]
                                : "",
                          },
                          [
                            _c(
                              "b-badge",
                              {
                                attrs: {
                                  variant:
                                    row.item.status == "delete"
                                      ? "light"
                                      : "outline-info",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            Week " +
                                    _vm._s(row.item.week) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (row) {
                  return [
                    _c("div", [
                      !row.item.editing && row.item.status !== "delete"
                        ? _c("i", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content: `Edit`,
                                  placement: "bottom-center",
                                  classes: ["light"],
                                  delay: {
                                    show: 500,
                                    hide: 300,
                                  },
                                  offset: "5",
                                },
                                expression:
                                  "{\n              content: `Edit`,\n              placement: 'bottom-center',\n              classes: ['light'],\n              delay: {\n                show: 500,\n                hide: 300\n              },\n              offset: '5'\n            }",
                              },
                            ],
                            staticClass:
                              "simple-icon-note text-primary align-self-center",
                            on: {
                              click: function ($event) {
                                return _vm.doEdit(row.item)
                              },
                            },
                          })
                        : row.item.editing && row.item.status !== "delete"
                        ? _c("i", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content: `Save`,
                                  placement: "bottom-center",
                                  classes: ["light"],
                                  delay: {
                                    show: 500,
                                    hide: 300,
                                  },
                                  offset: "5",
                                },
                                expression:
                                  "{\n              content: `Save`,\n              placement: 'bottom-center',\n              classes: ['light'],\n              delay: {\n                show: 500,\n                hide: 300\n              },\n              offset: '5'\n            }",
                              },
                            ],
                            staticClass: "simple-icon-check text-primary",
                            on: {
                              click: function ($event) {
                                return _vm.doSave(row.item)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      row.item.status == "delete"
                        ? _c("i", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content: `Recover`,
                                  placement: "bottom-center",
                                  classes: ["danger"],
                                  delay: {
                                    show: 500,
                                    hide: 300,
                                  },
                                  offset: "5",
                                },
                                expression:
                                  "{\n              content: `Recover`,\n              placement: 'bottom-center',\n              classes: ['danger'],\n              delay: {\n                show: 500,\n                hide: 300\n              },\n              offset: '5'\n            }",
                              },
                            ],
                            staticClass: "simple-icon-reload text-danger",
                            on: {
                              click: function ($event) {
                                return _vm.doRecover(row.item)
                              },
                            },
                          })
                        : _c("i", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content: `Delete`,
                                  placement: "bottom-center",
                                  classes: ["danger"],
                                  delay: {
                                    show: 500,
                                    hide: 300,
                                  },
                                  offset: "5",
                                },
                                expression:
                                  "{\n              content: `Delete`,\n              placement: 'bottom-center',\n              classes: ['danger'],\n              delay: {\n                show: 500,\n                hide: 300\n              },\n              offset: '5'\n            }",
                              },
                            ],
                            staticClass: "simple-icon-trash ml-2 text-danger",
                            on: {
                              click: function ($event) {
                                return _vm.doDelete(row.item)
                              },
                            },
                          }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("button", { ref: "submit", staticStyle: { display: "none" } }, [
            _vm._v("Submit"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }