var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "overall-result-chart" },
    [
      _c(
        "b-overlay",
        {
          attrs: { show: _vm.loading, rounded: "sm", "bg-color": "white" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [_c("running-loader", { attrs: { loading: "" } })]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.isBarChart
            ? _c(
                "div",
                [
                  _vm.shadow
                    ? _c("bar-shadow-chart", {
                        attrs: {
                          data: _vm.data,
                          options: _vm.options,
                          height: _vm.height,
                        },
                      })
                    : _c("bar-chart", {
                        attrs: {
                          "chart-data": _vm.data,
                          options: _vm.options,
                          height: _vm.height,
                        },
                      }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _vm.shadow
                    ? _c("line-shadow-chart", {
                        attrs: {
                          data: _vm.data,
                          options: _vm.options,
                          height: _vm.height,
                        },
                      })
                    : _c("line-chart", {
                        attrs: {
                          "chart-data": _vm.data,
                          options: _vm.options,
                          height: _vm.height,
                        },
                      }),
                ],
                1
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }