var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chart-legend overall-result w-100" },
    [
      _c(
        "b-row",
        { staticClass: "mx-2 legend-item mb-2" },
        _vm._l(_vm.chartDataSet, function (data, index) {
          return _c(
            "b-col",
            {
              key: `chart-data-${index}`,
              staticClass: "text-center mb-2 p-0",
              class: { "option-hidden": data.hidden },
            },
            [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: data.label,
                        placement: "top",
                        classes: ["light"],
                      },
                      expression:
                        "{\n          content: data.label,\n          placement: 'top',\n          classes: ['light']\n        }",
                    },
                  ],
                  staticClass:
                    "align-self-center m-0 font-semi-bold team-name mx-auto",
                },
                [_vm._v("\n        " + _vm._s(data.label) + "\n      ")]
              ),
              _vm._v(" "),
              _vm.formatType === 2
                ? _c(
                    "p",
                    { staticClass: "m-0 text-center font-light font-size-90" },
                    [
                      _vm._v(
                        "\n        (" +
                          _vm._s(_vm._f("formatPayment")(data.data[0])) +
                          ")\n      "
                      ),
                    ]
                  )
                : _vm.formatType === 3
                ? _c(
                    "p",
                    { staticClass: "m-0 text-center font-light font-size-90" },
                    [
                      _vm._v(
                        "\n        (" +
                          _vm._s(_vm._f("formatRatePercentage")(data.data[0])) +
                          ")\n      "
                      ),
                    ]
                  )
                : _c(
                    "p",
                    { staticClass: "m-0 text-center font-light font-size-90" },
                    [_vm._v("(" + _vm._s(data.data[0]) + ")")]
                  ),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "px-3 py-1 mb-1 mx-auto align-self-center chart-legend-line",
                style: `border: 1px solid ${data.borderColor}; backgroundColor: ${data.backgroundColor}`,
              }),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }