<template>
  <div class="game-financials-control">
    <b-card class="game-financials-card" body-class="game-financials-card-content">
      <div class="header d-flex mb-4">
        <h4 class="m-0 animate__animated animate__fadeIn">Game Financials</h4>
      </div>
      <div class="w-100 d-flex flex-column flex-lg-row py-2 game-financials-header">
        <p class="mb-0 w-20 w-sm-100 align-middle text-center">Description</p>
        <p class="mb-0 w-20 w-sm-100 align-middle text-center" @click="orderGameFinancials">
          <span>Amount</span>
          <i class="fa ml-1" :class="orderedGameFinancials ? 'fa-caret-up' : 'fa-caret-down'"></i>
        </p>
        <p class="mb-0 w-20 w-sm-100 align-middle text-center">Type</p>
        <p class="mb-0 w-15 w-sm-100 align-middle text-center">Week</p>
        <p class="mb-0 w-15 w-sm-100 align-middle text-center">Day</p>
        <p class="mb-0 w-10 w-sm-100 align-middle text-center">Actions</p>
      </div>
      <game-financials-control-form
        :key="`new-financial-form-${reloadedForm}`"
        v-model="newFinancial"
        :loading="loading"
        :processing="processing"
        @itemAdded="onNewFinancialAdd"
        class="my-3"
      ></game-financials-control-form>
      <b-overlay :show="loadingGameFinancials" rounded="sm" bg-color="white">
        <vue-perfect-scrollbar
          class="scroll"
          :settings="{ suppressScrollX: true, wheelPropagation: false }"
        >
          <div
            v-for="(gameFinancial, index) in sortGameFinancials"
            :key="`fin-${index}`"
            class="mb-2"
          >
            <game-financials-control-form
              v-if="editedItemIds.includes(gameFinancial.id)"
              key="new-financial-form"
              edit
              v-model="sortGameFinancials[index]"
              @itemEdited="onItemEditConfirm(sortGameFinancials[index])"
              @itemCancelled="onItemEditCancel(sortGameFinancials[index])"
              class="my-3"
            ></game-financials-control-form>
            <game-financials-item
              v-else
              :key="`fin-item-${index}-${sortGameFinancials[index].status}`"
              v-model="sortGameFinancials[index]"
              @edited="onItemEdit(sortGameFinancials[index])"
              @deleted="onItemDelete(sortGameFinancials[index])"
              class="d-flex py-3 financial-item animate__animated animate__fadeIn"
            ></game-financials-item>
          </div>
        </vue-perfect-scrollbar>
        <template #overlay>
          <running-loader loading></running-loader>
        </template>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import GameFinancialsControlForm from './GameFinancialsControlForm.vue'
import GameFinancialsItem from './GameFinancialsItem.vue'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
import { helperMixin } from '@/mixins/helperMixin'
import moment from 'moment'
export default {
  props: {
    teamId: {
      type: [Number, String],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    GameFinancialsControlForm,
    GameFinancialsItem,
    ProcessingButton
  },
  data() {
    return {
      processing: false,
      loadingGameFinancials: false,
      financialTypes: ['Debit', 'Credit'],
      newFinancial: this.createNewFinancial(),
      allGameFinancials: [],
      teamWeeks: [],
      teamWeekDays: [],
      gameFinancialChanged: false,
      reloadedForm: 0,
      editedItemIds: [],
      orderedGameFinancials: false
    }
  },
  mixins: [helperMixin],
  methods: {
    ...mapActions([
      'fetchTeamGameFinancials',
      'addTeamGameFinancials',
      'fetchNewTeamGameFinancials',
      'updateTeamGameFinancials',
      'deleteTeamGameFinancials'
    ]),
    createNewFinancial() {
      return {
        id: this.createUUID(),
        description: '',
        amount: null,
        type: 'Credit',
        team_week_id: null,
        team_week_name: 'loading...',
        team_week_day_id: null,
        team_week_day_name: 'loading...',
        editing: false,
        status: 'add',
        created_at: moment().format()
      }
    },
    async getGameFinancials() {
      let self = this
      self.loadingGameFinancials = true
      await self.fetchTeamGameFinancials(self.teamId)
      self.loadingGameFinancials = false
      self.allGameFinancials = await _.cloneDeep(self.gameFinancials)
      await self.allGameFinancials.map((fin) => {
        fin.editing = true
        fin.status = null
      })
    },
    orderGameFinancials() {
      let self = this
      self.orderedGameFinancials = !self.orderedGameFinancials
      self.sortGameFinancials.sort((a, b) =>
        self.orderedGameFinancials ? b.amount - a.amount : a.amount - b.amount
      )
    },
    async onNewFinancialAdd() {
      let self = this
      let item = await self.newFinancial
      let payload = [
        {
          team_id: self.teamId,
          team_week_id: item.team_week_id,
          team_week_day_id: item.team_week_day_id,
          financial_id: null,
          financial_description: item.description,
          financial_amount: Number(item.amount),
          financial_type: item.type.toUpperCase(),
          editing: item.editing,
          status: item.status
        }
      ]
      self.newFinancial = await self.createNewFinancial()
      self.reloadedForm++
      self.processing = true
      await self.addTeamGameFinancials(payload)
      await self.fetchNewTeamGameFinancials(self.teamId)
      self.allGameFinancials = await _.cloneDeep(self.gameFinancials)
      self.processing = false
    },
    async onItemEditConfirm(item) {
      let self = this
      let post = [
        {
          team_id: self.teamId,
          team_week_id: item.team_week_id,
          team_week_day_id: item.team_week_day_id,
          financial_id: item.status === 'add' ? null : item.id,
          financial_description: item.description,
          financial_amount: Number(item.amount),
          financial_type: item.type.toUpperCase(),
          editing: true,
          status: 'update'
        }
      ]

      let state = [
        {
          id: item.id,
          team_week_id: item.team_week_id,
          type: item.type.toUpperCase(),
          amount: Number(item.amount),
          created_at: item.created_at,
          updated_at: item.created_at,
          team_week_day_id: item.team_week_day_id,
          description: item.description,
          team_week_name: item.team_week_name,
          team_week_day_name: item.team_week_day_name,
          status: item.status
        }
      ]

      await self.removeItem(self.editedItemIds, item.id)
      await self.updateTeamGameFinancials({ post: post, state: state })
      await self.resetData()
    },
    onItemEditCancel(item) {
      let self = this
      let gameFinancials = self.allGameFinancials
      let index = gameFinancials.indexOf(item)
      // check if item property is changed and restore item with existing one
      if (item.id) {
        let existingItem = _.cloneDeep(self.gameFinancials.find((i) => i.id === item.id))
        existingItem.editing = true
        existingItem.status = null
        if (!_.isEqual(item, existingItem)) {
          self.$set(gameFinancials, index, existingItem)
        }
        self.removeItem(self.editedItemIds, item.id)
      }
    },
    onItemEdit(item) {
      let self = this
      self.editedItemIds.push(item.id)
    },
    async onItemDelete(item) {
      let self = this
      let gameFinancials = await self.allGameFinancials
      let index = await gameFinancials.indexOf(item)
      let payload = [
        {
          team_id: self.teamId,
          team_week_id: item.team_week_id,
          team_week_day_id: item.team_week_day_id,
          financial_id: item.id,
          financial_description: item.description,
          financial_amount: item.amount,
          financial_type: item.type.toUpperCase(),
          editing: item.editing,
          status: 'delete'
        }
      ]
      await self.$delete(gameFinancials, index)
      await self.deleteTeamGameFinancials(payload)
    },
    removeItem(items, item) {
      const itemIndex = items.indexOf(item)
      if (itemIndex > -1) items.splice(itemIndex, 1)
    },
    async resetData() {
      let self = this
      self.gameFinancialChanged = false
      self.reloadedForm = 0
      self.editedItemIds = []
    }
  },
  computed: {
    ...mapGetters(['gameFinancials', 'allTeamWeekDays']),
    activeTeamWeeks() {
      let self = this
      return self.allTeamWeekDays.team_weeks
    },
    activeTeamWeek() {
      let self = this
      let teamWeek = self.activeTeamWeeks.find((tw) => tw.status_id === 2)
      return teamWeek
    },
    activeTeamWeekDay() {
      let self = this
      return self.activeTeamWeek.team_week_days.find((wd) => wd.current_day)
    },
    sortGameFinancials() {
      let self = this
      return _.orderBy(self.allGameFinancials, (item) => moment(item.created_at).utc(), ['desc'])
    }
  },
  created() {
    let self = this
    if (self.teamId) {
      self.getGameFinancials()
    }
  },
  watch: {
    teamId(newVal) {
      if (newVal) {
        let self = this
        self.resetData()
        self.getGameFinancials()
      }
    },
    sortGameFinancials: {
      handler(value) {
        if (value) {
          let self = this
          let updatedItems = value.filter((item) => item.status !== null)
          if (updatedItems.length > 0) {
            self.gameFinancialChanged = true
          } else {
            self.gameFinancialChanged = false
          }
        }
      },
      deep: true
    },
    allTeamWeekDays: {
      handler(value) {
        let self = this
        if (value) {
          self.getGameFinancials()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.game-financials {
  &-control {
    .scroll {
      padding-right: unset;
      margin-right: unset;
      height: 400px;

      .ps__thumb-y {
        right: 0;
      }

      .financial-item {
        border-bottom: 1px solid #f3f3f3;
      }
    }
  }

  &-header {
    p {
      font-family: 'Nunito Bold';
    }
  }

  &-card {
    height: 650px;
  }

  &-input {
    font-size: 0.8rem;
    padding: 0.3rem 0.75rem;
  }

  &-actions {
    font-size: 1.2rem;
  }
}

.ps {
  overflow-y: auto !important;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
  display: none; /* Safari and Chrome */
}
</style>
