var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-control" },
    [
      _c(
        "div",
        { staticClass: "header d-flex mb-3" },
        [
          _c("h1", { staticClass: "p-0 mb-0 align-self-end mr-3" }, [
            _vm._v("Game Control"),
          ]),
          _vm._v(" "),
          _c("team-selector", {
            staticClass: "align-self-end",
            attrs: { teams: _vm.teams, loading: _vm.loadingTeams },
            model: {
              value: _vm.activeTeam,
              callback: function ($$v) {
                _vm.activeTeam = $$v
              },
              expression: "activeTeam",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "mb-4",
              attrs: { cols: "12", xl: "5", lg: "12", md: "12" },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12 mb-4" } },
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "game-control-card",
                          attrs: { "body-class": "game-control-card-content" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "header d-flex mb-4" },
                            [
                              _c(
                                "h4",
                                {
                                  key: `wd-setting-title-${_vm.selectedWdToggleId}`,
                                  staticClass:
                                    "mb-0 align-self-center animate__animated animate__fadeIn",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.weekDaySettingTitle) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.selectedWdToggleId === 2,
                                      expression: "selectedWdToggleId === 2",
                                    },
                                  ],
                                  staticClass: "dropdown-menu-right",
                                  attrs: {
                                    variant: "empty",
                                    "toggle-class": "p-0 d-flex",
                                    "menu-class":
                                      "rollback-modal btn-shadow animate__animated animate__fadeIn",
                                    "no-caret": "",
                                  },
                                },
                                [
                                  _c("template", { slot: "button-content" }, [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-info-circle align-self-center text-info ml-2",
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-dropdown-form", [
                                    _c("div", [
                                      _c("h6", [_vm._v("Details:")]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "\n                      This feature allows you to rollback your team's progress to a particular day\n                      within the simulation.\n                    "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("h6", [_vm._v("Things to note:")]),
                                      _vm._v(" "),
                                      _c("ol", [
                                        _c("li", [
                                          _vm._v("Use this feature with care."),
                                        ]),
                                        _vm._v(" "),
                                        _c("li", [
                                          _vm._v(
                                            "\n                        The rollback feature can only be used to rollback to a day in the past.\n                        All data will be erased up until and including the selected day you\n                        rollback to.\n                      "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("li", [
                                          _vm._v(
                                            "\n                        There is no way to retrieve the forfeited data, once you have confirmed\n                        the rollback.\n                      "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("li", [
                                          _vm._v(
                                            "Please consider this before using this feature."
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.gameProcessing && _vm.selectedWdToggleId === 2
                                ? _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: {
                                          content: `Rollback is currenly not available, waiting for game day to close`,
                                          placement: "bottom",
                                          classes: ["light"],
                                          offset: 5,
                                        },
                                        expression:
                                          "{\n                  content: `Rollback is currenly not available, waiting for game day to close`,\n                  placement: 'bottom',\n                  classes: ['light'],\n                  offset: 5\n                }",
                                      },
                                    ],
                                    staticClass:
                                      "fa fa-ban align-self-center ml-2 text-danger animate__animated animate__fadeIn",
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("base-toggle-button", {
                                staticClass: "ml-auto align-self-center",
                                attrs: {
                                  options: _vm.weekDaySettingsToggle,
                                  buttonSize: 230,
                                },
                                model: {
                                  value: _vm.selectedWdToggleId,
                                  callback: function ($$v) {
                                    _vm.selectedWdToggleId = $$v
                                  },
                                  expression: "selectedWdToggleId",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("team-week-day-control", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.selectedWdToggleId === 1,
                                expression: "selectedWdToggleId === 1",
                              },
                            ],
                            key: `team-week-day-control-${_vm.componentUpdated}`,
                            staticClass: "animate__animated animate__fadeIn",
                            attrs: { loading: _vm.loadingAllTeamWeekDays },
                            on: { gameProcessing: _vm.processingGame },
                          }),
                          _vm._v(" "),
                          _c("team-week-day-rollback-control", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.selectedWdToggleId === 2,
                                expression: "selectedWdToggleId === 2",
                              },
                            ],
                            staticClass: "animate__animated animate__fadeIn",
                            attrs: {
                              loading: _vm.loadingAllTeamWeekDays,
                              disabled: _vm.gameProcessing,
                            },
                            on: { rollbackSaved: _vm.onRollbackSave },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "12 mb-4" } },
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "game-settings-card",
                          attrs: { "body-class": "game-control-card-content" },
                        },
                        [
                          _c("div", { staticClass: "header d-flex mb-4" }, [
                            _c(
                              "h4",
                              {
                                staticClass:
                                  "m-0 align-self-center animate__animated animate__fadeIn",
                              },
                              [
                                _vm._v(
                                  "\n                Game Settings\n              "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("game-settings-control", {
                            attrs: { teamId: _vm.activeTeam.id },
                            on: {
                              "update:teamId": function ($event) {
                                return _vm.$set(_vm.activeTeam, "id", $event)
                              },
                              "update:team-id": function ($event) {
                                return _vm.$set(_vm.activeTeam, "id", $event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("game-financials-control", {
                        attrs: {
                          teamId: _vm.activeTeam.id,
                          loading: _vm.loadingAllTeamWeekDays,
                        },
                        on: {
                          "update:teamId": function ($event) {
                            return _vm.$set(_vm.activeTeam, "id", $event)
                          },
                          "update:team-id": function ($event) {
                            return _vm.$set(_vm.activeTeam, "id", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "12", xl: "7", lg: "12", md: "12" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("work-allocations-table", {
                        attrs: { loading: _vm.loadingAllTeamWeekDays },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }