<template>
  <div class="w-100 d-flex flex-column flex-lg-row pb-1 game-financials-header">
    <div class="w-20 w-sm-100 align-self-center text-center px-2">
      <b-form-input
        v-model="gameFinancial.description"
        class="game-financials-input"
        v-tooltip="{
          content: `Description is required`,
          show: $v.gameFinancial.description.$error,
          trigger: 'manual',
          placement: 'bottom',
          classes: ['light'],
          offset: 5
        }"
      ></b-form-input>
    </div>
    <div class="w-20 w-sm-100 align-self-center text-center px-2">
      <b-form-input
        v-model="gameFinancial.amount"
        class="game-financials-input"
        type="number"
        min="0"
        number
        oninput="validity.valid||(value='');"
        v-tooltip="{
          content: `Amount is required`,
          show: $v.gameFinancial.amount.$error,
          trigger: 'manual',
          placement: 'bottom',
          classes: ['light'],
          offset: 5
        }"
      ></b-form-input>
    </div>
    <div class="w-20 w-sm-100 align-self-center text-center">
      <b-dropdown variant="empty" toggle-class="p-0" menu-class="a-shadow-sm" no-caret>
        <template slot="button-content">
          <b-badge :variant="setTypeVariant(gameFinancial.type)" pill>
            <span>{{ gameFinancial.type }}</span>
            <i class="fa fa-angle-down ml-1"></i>
          </b-badge>
        </template>
        <b-dropdown-item
          v-for="(item, index) in financialTypes"
          :key="`type-${index}`"
          :active="gameFinancial.type == item"
          @click="gameFinancial.type = item"
        >
          <b-badge :variant="setTypeVariant(item)" pill>{{ item }}</b-badge>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="w-15 w-sm-100 align-self-center text-center">
      <b-dropdown
        variant="empty"
        toggle-class="p-0"
        menu-class="a-shadow-sm"
        no-caret
        :disabled="loading"
      >
        <template slot="button-content">
          <span>{{ gameFinancial.team_week_name }}</span>
          <i class="fa fa-angle-down ml-1"></i>
        </template>
        <b-dropdown-item
          v-for="(item, index) in teamWeeks"
          :key="`type-${index}`"
          :active="gameFinancial.team_week_id === item.id"
          :disabled="item.status < 2"
          @click="onTeamWeekSelect(item)"
        >
          <span :class="{ 'text-muted': item.status < 2 }">{{ item.name }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="w-15 w-sm-100 align-self-center text-center">
      <b-dropdown
        variant="empty"
        toggle-class="p-0"
        menu-class="a-shadow-sm"
        no-caret
        :disabled="loadingTeamWeekDays"
      >
        <template slot="button-content">
          <span>{{ gameFinancial.team_week_day_name }}</span>
          <i class="fa fa-angle-down ml-1"></i>
        </template>
        <b-dropdown-item
          v-for="(item, index) in teamWeekDays"
          :key="`type-${index}`"
          :active="gameFinancial.team_week_day_id === item.id"
          :disabled="item.status < 3"
          @click="onTeamWeekDaySelect(item)"
        >
          <span :class="{ 'text-muted': item.status < 3 }">{{ item.name }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div v-if="!edit" class="w-10 w-sm-100 align-self-center text-center">
      <b-button
        size="xs"
        variant="new"
        :disabled="processing"
        @click="onItemAdd"
        class="btn-shadow"
      >
        Add
      </b-button>
    </div>
    <div v-else class="mb-0 w-10 w-sm-100 d-flex justify-content-center game-financials-actions">
      <i
        class="fa fa-check-circle align-self-center mr-2 text-danger"
        @click="onItemEdit"
        v-tooltip="{
          content: `Confirm edit`,
          placement: 'bottom',
          classes: ['light'],
          offset: 5
        }"
      ></i>
      <i
        class="fa fa-times-circle align-self-center text-muted"
        @click="onItemCancel"
        v-tooltip="{
          content: `Cancel edit`,
          placement: 'bottom',
          classes: ['light'],
          offset: 5
        }"
      ></i>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    },
    processing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      financialTypes: ['Debit', 'Credit'],
      teamWeeks: [],
      teamWeekDays: [],
      loadingTeamWeekDays: true
    }
  },
  mixins: [validationMixin],
  validations: {
    gameFinancial: {
      description: {
        required
      },
      amount: {
        required
      }
    }
  },
  methods: {
    setTypeVariant(type) {
      let variant = 'warning'
      if (type.toLowerCase() === 'credit') {
        variant = 'info'
      }

      return variant
    },
    async setTeamWeeksOptions() {
      let self = this
      let activeTeamWeeks = self.activeTeamWeeks
      let teamWeeks = await activeTeamWeeks.map((tw) => {
        return {
          id: tw.id,
          name: tw.week_name,
          status: tw.status_id
        }
      })

      self.teamWeeks = teamWeeks

      // get current open and complete weeks and latest week
      if (!self.edit) {
        let defaultTeamWeek = await teamWeeks
          .filter((tw) => tw.status >= 2)
          .sort((a, b) => b.id - a.id)[0]
        self.gameFinancial.team_week_id = defaultTeamWeek.id
        self.gameFinancial.team_week_name = defaultTeamWeek.name
      }

      // set weekday options
      await self.setTeamWeekDaysOptions()
    },
    async setTeamWeekDaysOptions() {
      let self = this
      let activeTeamWeeks = self.activeTeamWeeks
      self.loadingTeamWeekDays = true
      let teamWeekDays = await activeTeamWeeks
        .find((tw) => tw.id === self.gameFinancial.team_week_id)
        .team_week_days.map((twd) => {
          return {
            id: twd.id,
            name: twd.day_name,
            status: twd.status_id,
            currentDay: twd.current_day
          }
        })

      self.teamWeekDays = teamWeekDays

      // get current open and complete days and latest day
      if (!self.edit) {
        let defaultTeamWeekDay = await teamWeekDays
          .filter((twd) => twd.status >= 3)
          .sort((a, b) => b.id - a.id)[0]
        self.gameFinancial.team_week_day_id = defaultTeamWeekDay.id
        self.gameFinancial.team_week_day_name = defaultTeamWeekDay.name
      }

      self.loadingTeamWeekDays = false
    },
    setTeamWeekName(weekId) {
      let self = this
      return self.teamWeeks.find((tw) => tw.id === weekId).name
    },
    async onTeamWeekSelect(week) {
      let self = this
      self.gameFinancial.team_week_id = week.id
      self.gameFinancial.team_week_name = week.name
      await self.setTeamWeekDaysOptions()
    },
    onTeamWeekDaySelect(day) {
      let self = this
      self.gameFinancial.team_week_day_id = day.id
      self.gameFinancial.team_week_day_name = day.name
    },
    async onItemAdd() {
      let self = this
      await self.$v.$touch()

      // return if form error is captured
      if (self.$v.gameFinancial.$pending || self.$v.gameFinancial.$error) return

      self.$emit('itemAdded')
      await self.resetData()
    },
    async onItemEdit() {
      let self = this
      await self.$v.$touch()

      // return if form error is captured
      if (self.$v.gameFinancial.$pending || self.$v.gameFinancial.$error) return

      self.$emit('itemEdited')
      await self.resetData()
    },
    async onItemCancel() {
      let self = this
      self.$emit('itemCancelled')
    },
    resetData() {
      let self = this
      self.$v.$reset()
    }
  },
  computed: {
    ...mapGetters(['allTeamWeekDays']),
    gameFinancial: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    activeTeamWeeks() {
      let self = this
      return self.allTeamWeekDays.team_weeks
    }
  },
  created() {
    if (this.allTeamWeekDays) {
      this.setTeamWeeksOptions()
    }
  },
  watch: {
    loading(newValue) {
      if (!newValue) {
        let self = this
        self.setTeamWeeksOptions()
      }
    },
    allTeamWeekDays: {
      handler(value) {
        this.setTeamWeeksOptions()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.game-financials {
  &-input {
    font-size: 0.8rem;
    padding: 0.3rem 0.75rem;
  }

  &-actions {
    i {
      font-size: 1.2rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
