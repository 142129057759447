var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-settings-control" },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onGameSettingsSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "d-flex" }, [
            _c("div", { staticClass: "align-self-center mr-4" }, [
              _c("label", { staticClass: "settings-label pl-0 mb-2 col-12" }, [
                _vm._v("Require approval code?"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "b-form-radio",
                    {
                      staticClass: "align-self-center d-inline-block",
                      attrs: {
                        name: "ac-radios",
                        value: 1,
                        disabled: _vm.loadingGameSettings,
                      },
                      model: {
                        value: _vm.approvalCodeValidation,
                        callback: function ($$v) {
                          _vm.approvalCodeValidation = $$v
                        },
                        expression: "approvalCodeValidation",
                      },
                    },
                    [_c("span", [_vm._v("Yes")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-radio",
                    {
                      staticClass: "align-self-center d-inline-block mx-4",
                      attrs: {
                        name: "ac-radios",
                        value: 0,
                        disabled: _vm.loadingGameSettings,
                      },
                      model: {
                        value: _vm.approvalCodeValidation,
                        callback: function ($$v) {
                          _vm.approvalCodeValidation = $$v
                        },
                        expression: "approvalCodeValidation",
                      },
                    },
                    [_c("span", [_vm._v("No")])]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "align-self-center" }, [
              _c("label", { staticClass: "settings-label pl-0 mb-2 col-12" }, [
                _vm._v("Work Complexity"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "b-form-radio",
                    {
                      staticClass: "align-self-center d-inline-block",
                      attrs: {
                        name: "wc-radios",
                        value: 2,
                        disabled: _vm.loadingGameSettings,
                      },
                      model: {
                        value: _vm.workComplexity,
                        callback: function ($$v) {
                          _vm.workComplexity = $$v
                        },
                        expression: "workComplexity",
                      },
                    },
                    [_c("span", [_vm._v("Letter")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-radio",
                    {
                      staticClass: "align-self-center d-inline-block mx-4",
                      attrs: {
                        name: "wc-radios",
                        value: 1,
                        disabled: _vm.loadingGameSettings,
                      },
                      model: {
                        value: _vm.workComplexity,
                        callback: function ($$v) {
                          _vm.workComplexity = $$v
                        },
                        expression: "workComplexity",
                      },
                    },
                    [_c("span", [_vm._v("Table")])]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex action-button" }, [
            _c(
              "div",
              { staticClass: "align-self-center ml-auto" },
              [
                _c("processing-button", {
                  key: "btn-work",
                  staticClass: "align-self-center btn-shadow ml-auto",
                  attrs: {
                    variant: "primary",
                    size: "sm",
                    label: "Save",
                    processing: _vm.processing,
                    disabled: !_vm.gameSettingsChanged,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }