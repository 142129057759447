var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "work-allocation-base-input" },
    [
      _c("b-form-input", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: {
              content: _vm.error,
              show: _vm.validate && _vm.v.$error,
              trigger: "manual",
              placement: "bottom",
              classes: ["danger"],
            },
            expression:
              "{\n      content: error,\n      show: validate && v.$error,\n      trigger: 'manual',\n      placement: 'bottom',\n      classes: ['danger']\n    }",
          },
        ],
        attrs: {
          type: _vm.type,
          placeholder: _vm.placeholder,
          readonly: _vm.readonly,
          autocomplete: "off",
          step: _vm.step,
        },
        on: {
          input: function ($event) {
            _vm.validate ? _vm.v.$touch() : null
          },
        },
        model: {
          value: _vm.name,
          callback: function ($$v) {
            _vm.name = $$v
          },
          expression: "name",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }