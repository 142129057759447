var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "form-body" },
    [
      _c("b-col", { attrs: { cols: "12 my-2" } }, [
        _c("label", { staticClass: "settings-label pl-0 mb-2 col-12" }, [
          _vm._v("Require approval code?"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "b-form-radio",
              {
                staticClass: "align-self-center d-inline-block",
                attrs: { name: "ac-radios", value: 1 },
                model: {
                  value: _vm.settings.approvalCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.settings, "approvalCode", $$v)
                  },
                  expression: "settings.approvalCode",
                },
              },
              [_c("span", [_vm._v("Yes")])]
            ),
            _vm._v(" "),
            _c(
              "b-form-radio",
              {
                staticClass: "align-self-center d-inline-block mx-4",
                attrs: { name: "ac-radios", value: 0 },
                model: {
                  value: _vm.settings.approvalCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.settings, "approvalCode", $$v)
                  },
                  expression: "settings.approvalCode",
                },
              },
              [_c("span", [_vm._v("No")])]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("b-col", { attrs: { cols: "12 my-2" } }, [
        _c("label", { staticClass: "settings-label pl-0 mb-2 col-12" }, [
          _vm._v("Work Complexity"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "b-form-radio",
              {
                staticClass: "align-self-center d-inline-block",
                attrs: { name: "wc-radios", value: 2 },
                model: {
                  value: _vm.settings.complexity,
                  callback: function ($$v) {
                    _vm.$set(_vm.settings, "complexity", $$v)
                  },
                  expression: "settings.complexity",
                },
              },
              [_c("span", [_vm._v("Letter")])]
            ),
            _vm._v(" "),
            _c(
              "b-form-radio",
              {
                staticClass: "align-self-center d-inline-block mx-4",
                attrs: { name: "wc-radios", value: 1 },
                model: {
                  value: _vm.settings.complexity,
                  callback: function ($$v) {
                    _vm.$set(_vm.settings, "complexity", $$v)
                  },
                  expression: "settings.complexity",
                },
              },
              [_c("span", [_vm._v("Table")])]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { cols: "12 my-2" } },
        [
          _c("label", { staticClass: "settings-label pl-0 mb-2 col-12" }, [
            _vm._v("Currency"),
          ]),
          _vm._v(" "),
          _c(
            "b-dropdown",
            {
              attrs: {
                variant: "empty",
                "toggle-class": "p-0",
                "menu-class": "mt-2 a-shadow-sm",
                "no-caret": "",
              },
            },
            [
              _c(
                "template",
                { slot: "button-content" },
                [
                  _vm.selectedCurrency
                    ? _c(
                        "b-badge",
                        {
                          key: `currency-${_vm.selectedCurrency.id}`,
                          staticClass:
                            "btn-shadow animate__animated animate__bounceIn",
                          attrs: { pill: "", variant: "warning" },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.selectedCurrency.name) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.localeOptions, function (item, index) {
                return _c(
                  "b-dropdown-item",
                  {
                    key: `item-${index}`,
                    on: {
                      click: function ($event) {
                        return _vm.onItemClick(item)
                      },
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(item.name))])]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }