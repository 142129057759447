<template>
  <div class="game-control">
    <div class="header d-flex mb-3">
      <h1 class="p-0 mb-0 align-self-end mr-3">Game Control</h1>
      <team-selector
        :teams="teams"
        :loading="loadingTeams"
        v-model="activeTeam"
        class="align-self-end"
      ></team-selector>
    </div>
    <b-row>
      <b-col cols="12" xl="5" lg="12" md="12" class="mb-4">
        <b-row>
          <b-col cols="12 mb-4">
            <b-card class="game-control-card" body-class="game-control-card-content">
              <div class="header d-flex mb-4">
                <h4
                  :key="`wd-setting-title-${selectedWdToggleId}`"
                  class="mb-0 align-self-center animate__animated animate__fadeIn"
                >
                  {{ weekDaySettingTitle }}
                </h4>
                <b-dropdown
                  v-show="selectedWdToggleId === 2"
                  class="dropdown-menu-right"
                  variant="empty"
                  toggle-class="p-0 d-flex"
                  menu-class="rollback-modal btn-shadow animate__animated animate__fadeIn"
                  no-caret
                >
                  <template slot="button-content">
                    <i class="fa fa-info-circle align-self-center text-info ml-2"></i>
                  </template>
                  <b-dropdown-form>
                    <div>
                      <h6>Details:</h6>
                      <p>
                        This feature allows you to rollback your team's progress to a particular day
                        within the simulation.
                      </p>
                      <h6>Things to note:</h6>
                      <ol>
                        <li>Use this feature with care.</li>
                        <li>
                          The rollback feature can only be used to rollback to a day in the past.
                          All data will be erased up until and including the selected day you
                          rollback to.
                        </li>
                        <li>
                          There is no way to retrieve the forfeited data, once you have confirmed
                          the rollback.
                        </li>
                        <li>Please consider this before using this feature.</li>
                      </ol>
                    </div>
                  </b-dropdown-form>
                </b-dropdown>
                <i
                  v-if="gameProcessing && selectedWdToggleId === 2"
                  class="fa fa-ban align-self-center ml-2 text-danger animate__animated animate__fadeIn"
                  v-tooltip="{
                    content: `Rollback is currenly not available, waiting for game day to close`,
                    placement: 'bottom',
                    classes: ['light'],
                    offset: 5
                  }"
                ></i>
                <base-toggle-button
                  :options="weekDaySettingsToggle"
                  :buttonSize="230"
                  v-model="selectedWdToggleId"
                  class="ml-auto align-self-center"
                ></base-toggle-button>
              </div>
              <team-week-day-control
                v-show="selectedWdToggleId === 1"
                :key="`team-week-day-control-${componentUpdated}`"
                :loading="loadingAllTeamWeekDays"
                @gameProcessing="processingGame"
                class="animate__animated animate__fadeIn"
              ></team-week-day-control>
              <team-week-day-rollback-control
                v-show="selectedWdToggleId === 2"
                :loading="loadingAllTeamWeekDays"
                :disabled="gameProcessing"
                class="animate__animated animate__fadeIn"
                @rollbackSaved="onRollbackSave"
              ></team-week-day-rollback-control>
            </b-card>
          </b-col>

          <b-col cols="12 mb-4">
            <b-card class="game-settings-card" body-class="game-control-card-content">
              <div class="header d-flex mb-4">
                <h4 class="m-0 align-self-center animate__animated animate__fadeIn">
                  Game Settings
                </h4>
              </div>
              <game-settings-control :teamId.sync="activeTeam.id"></game-settings-control>
            </b-card>
          </b-col>

          <b-col cols="12">
            <game-financials-control
              :teamId.sync="activeTeam.id"
              :loading="loadingAllTeamWeekDays"
            ></game-financials-control>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" xl="7" lg="12" md="12">
        <b-row>
          <b-col cols="12">
            <work-allocations-table :loading="loadingAllTeamWeekDays"></work-allocations-table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { formMixin } from '@/mixins/formMixin'
import { helperMixin } from '@/mixins/helperMixin'
import TeamSelector from '@/components/Control/TeamSelector.vue'
import OverlayLoader from '@/components/Common/OverlayLoader.vue'
import TeamWeekDayControl from '@/components/Control/TeamWeekDayControl.vue'
import TeamWeekDayRollbackControl from '@/components/Control/TeamWeekDayRollbackControl.vue'
import WorkAllocationsControl from '@/components/Control/WorkAllocationsControl.vue'
import WorkAllocationsTable from '@/components/Control/WorkAllocationsTable.vue'
import GameFinancialsControlSlideOne from '@/components/Control/GameFinancialsControlSlideOne.vue'
import GameFinancialsControlSlideTwo from '@/components/Control/GameFinancialsControlSlideTwo.vue'
import GameSettingsControl from '@/components/Control/GameSettingsControl.vue'
import GameFinancialsControl from '@/components/Control/GameFinancialsControl.vue'
import { quickAllocations } from '@/constants/config'
import IconToggle from '@/components/Common/IconToggle.vue'
import BaseToggleButton from '@/components/Common/BaseToggleButton.vue'
import { gameSettingsToggle } from '@/data/admin'
import { weekDaySettingsToggle } from '@/components/Control/config'
export default {
  components: {
    TeamSelector,
    OverlayLoader,
    TeamWeekDayControl,
    TeamWeekDayRollbackControl,
    WorkAllocationsControl,
    WorkAllocationsTable,
    GameFinancialsControlSlideOne,
    GameFinancialsControlSlideTwo,
    GameSettingsControl,
    GameFinancialsControl,
    IconToggle,
    BaseToggleButton
  },
  mixins: [formMixin, helperMixin],
  data() {
    return {
      configuration: this.createNewConfiguration(),
      activeTeamIndex: 0,
      activeTeam: this.createNewTeam(),
      loading: true,
      loadingTeams: true,
      loadingTeamWeeks: true,
      loadingTeamWeekDays: true,
      loadingAllTeamWeekDays: true,
      loadingAllTeamWorkAllocations: true,
      originalActiveTeam: [],
      teams: [],
      teamWeeks: null,
      currentTeamWeekDays: null,
      completedTeamWeekDays: null,
      teamWorkAllocations: null,
      message: '',
      runningOutWarning: false,
      showModal: false,
      gameProcessing: false,
      updatedTeamWorkAllocations: null,
      processingSettings: false,
      updatedSettings: {
        slideOne: null,
        slideTwo: []
      },
      componentUpdated: 0,
      quickAllocations,
      quickAssigned: 0,
      workAllocationsControlStatus: true,
      gameSettingSlide: 1,
      gameFinancialsControlStatus: true,
      selectedToggle: 1,
      gameSettingsToggle,
      weekDaySettingsToggle,
      selectedWdToggleId: 1,
      loadingAppConfiguration: false,
      loadingGameSettings: true,
      selectedTestToggle: 1
    }
  },
  methods: {
    ...mapActions([
      'fetchTeams',
      'fetchAllTeamWeeks',
      'fetchTeamWeekDays',
      'fetchAllTeamWeekDays',
      'submitTeamWeekSettings',
      'submitConfiguration',
      'fetchAppConfiguration',
      'fetchTeamGameSettings'
    ]),
    createNewConfiguration() {
      return {
        locale: null,
        currency: null
      }
    },
    createNewTeam() {
      return {
        id: null,
        name: '',
        avatar: ''
      }
    },
    async getAppConfiguration() {
      let self = this
      self.loadingAppConfiguration = true
      await self.fetchAppConfiguration()
      self.configuration.locale = self.locale
      self.configuration.currency = self.currency
      self.loadingAppConfiguration = false
    },
    async getTeams() {
      let self = this
      if (self.hasLeaderRole) {
        self.activeTeam.id = self.currentUser.teamId
        self.activeTeam.name = self.currentUser.team
        self.activeTeam.avatar = self.currentUser.teamImg
        self.teams.push(self.activeTeam)
        self.loadingTeams = false
        return
      }

      await self.fetchTeams()
      self.teams = self.allTeams
      self.originalActiveTeam = _.cloneDeep(self.teams[self.activeTeamIndex])
      self.activeTeam = self.teams[self.activeTeamIndex]
      self.loadingTeams = false
    },
    async getAllTeamWeekDays() {
      let self = this
      await self.fetchAllTeamWeekDays(self.activeTeam.id)
      self.loadingAllTeamWeekDays = false
    },
    async getTeamWeeks() {
      let self = this
      await self.fetchAllTeamWeeks(self.activeTeam.id)
      self.teamWeeks = self.allTeamWeeks
      self.loadingTeamWeeks = false
    },
    async getTeamGameSettings() {
      let self = this
      self.loadingGameSettings = true
      await self.fetchTeamGameSettings(self.activeTeam.id)
      self.loadingGameSettings = false
    },
    async getTeamData() {
      let self = this
      self.loading = true
      self.loadingAllTeamWeekDays = true
      self.loadingTeamWeekDays = true
      await self.getAllTeamWeekDays()
      await self.getTeamWeeks()
      self.loading = false
    },
    async reloadWorkAllocations() {
      let self = this
      self.teamWorkAllocations = null
      await self.getTeamWorkAllocations()
      self.quickAssigned = 0
    },
    async processingGame(value) {
      let self = this
      self.gameProcessing = value
    },
    async onRollbackSave() {
      let self = this
      self.loadingAllTeamWeekDays = true
      self.selectedWdToggleId = 1 // switch to game progression
      await self.getAllTeamWeekDays()
    }
  },
  computed: {
    ...mapGetters([
      'allTeams',
      'allTeamWeeks',
      'teamWeekDays',
      'allTeamWeekDays',
      'locale',
      'currency'
    ]),
    weekDaySettingTitle() {
      return this.weekDaySettingsToggle.find((s) => s.id === this.selectedWdToggleId).title
    },
    activeTeamWeekDays() {
      let self = this
      return self.allTeamWeekDays.team_weeks.find((wd) => wd.status_id === 2)
    },
    activeTeamWeekDay() {
      let self = this
      return self.activeTeamWeekDays.team_week_days.find((wd) => wd.status_id === 3)
    },
    activeTeamWorkAllocations() {
      let self = this
      return self.teamWorkAllocations.find((wa) => wa.teamId === self.activeTeam.id)
    },
    quickAssignedOptions() {
      let self = this
      let max = self.quickAllocations.max
      let step = self.quickAllocations.step
      return _.range(5, max + step, step)
    },
    gameSettingTitle() {
      let self = this
      let toggle = self.gameSettingsToggle.find((t) => t.id === self.selectedToggle)
      return toggle.title
    }
  },
  async created() {
    let self = this
    self.loadingTeams = true
    self.loadingTeamWeeks = true
    await self.getTeams()
    await self.getTeamData()
    // await self.getAppConfiguration()
  },
  watch: {
    componentUpdated() {
      this.reloadWorkAllocations()
    },
    activeTeam() {
      this.getTeamData()
    }
  }
}
</script>
