var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "mb-4 round-breakdown-table" },
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-card",
            { staticClass: "table-card", attrs: { "no-body": "" } },
            [
              _c("div", { staticClass: "d-flex mt-4 mx-4" }, [
                _c("h5", { staticClass: "m-0 align-self-center" }, [
                  _vm._v("Revenue and Cost"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "align-self-center" },
                  [
                    !_vm.loadingWeeks && _vm.allTeamWeeks
                      ? _c("single-selection-filter", {
                          staticClass: "ml-2 align-self-center",
                          attrs: {
                            itemValue: "name",
                            options: _vm.allWeeks,
                            placeholder: "Select week",
                            badge: "",
                            defaultItemIndex: 0,
                          },
                          model: {
                            value: _vm.selectedWeek,
                            callback: function ($$v) {
                              _vm.selectedWeek = $$v
                            },
                            expression: "selectedWeek",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("scale-loader", {
                      staticClass: "ml-2 align-self-center",
                      attrs: {
                        loading: _vm.loadingWeeks,
                        color: _vm.loader.color,
                        height: "15px",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mx-4 my-2" },
                [
                  _c(
                    "b-overlay",
                    {
                      attrs: {
                        show: _vm.loadingTable,
                        rounded: "sm",
                        "bg-color": "white",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "overlay",
                          fn: function () {
                            return [
                              _c("running-loader", { attrs: { loading: "" } }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      !_vm.loadingTable && _vm.roundBreakdown
                        ? _c("b-table", {
                            key: `data-table-${_vm.refreshTable}`,
                            ref: "table",
                            attrs: {
                              fields: _vm.roundBreakdown.fields,
                              items: _vm.roundBreakdown.data,
                              "tbody-tr-class": _vm.rowClass,
                              hover: "",
                              responsive: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "thead-top",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-tr",
                                        _vm._l(
                                          _vm.roundBreakdown.fieldGroup,
                                          function (th, index) {
                                            return _c(
                                              "b-th",
                                              {
                                                key: `th-${index}`,
                                                class: {
                                                  "th-bg-theme-secondary":
                                                    th.show,
                                                },
                                                attrs: { colspan: th.items },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "mb-0 font-semi-bold team-name",
                                                    class: {
                                                      "sr-only": !th.show,
                                                    },
                                                    attrs: {
                                                      id: `th-title-${index}`,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(th.title) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "b-tooltip",
                                                  {
                                                    attrs: {
                                                      target: `th-title-${index}`,
                                                      placement: "top",
                                                      variant: "light",
                                                      boundary: "window",
                                                      triggers: "hover",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(th.title) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass:
                                                    "px-3 py-1 mb-1 align-self-center chart-legend-line",
                                                  style: `border: 1px solid ${th.borderColor}; backgroundColor: ${th.backgroundColor}`,
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                _vm._l(
                                  _vm.roundBreakdown.fields,
                                  function (field, index) {
                                    return {
                                      key: `cell(${field.key})`,
                                      fn: function (data) {
                                        return [
                                          field.key == "options"
                                            ? _c(
                                                "div",
                                                { key: `field-${index}` },
                                                [
                                                  data.item.type
                                                    ? _c(
                                                        "b-badge",
                                                        {
                                                          attrs: {
                                                            variant:
                                                              data.item.type.toLowerCase() ===
                                                              "revenue"
                                                                ? "success"
                                                                : "warning",
                                                            pill: "",
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                data.item.type
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "ml-2",
                                                      class: {
                                                        "font-semi-bold":
                                                          data.item.option
                                                            .toLowerCase()
                                                            .includes("total"),
                                                        "font-bold":
                                                          data.item.option.toLowerCase() ===
                                                          "total profit",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            data.item.option
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  data.item.option_desc.length >
                                                  0
                                                    ? _c("i", {
                                                        directives: [
                                                          {
                                                            name: "tooltip",
                                                            rawName:
                                                              "v-tooltip",
                                                            value: {
                                                              content: `${data.item.option_desc[0]}`,
                                                              placement:
                                                                "right-center",
                                                              classes: [
                                                                "light",
                                                              ],
                                                              delay: {
                                                                show: 500,
                                                                hide: 300,
                                                              },
                                                              offset: "5",
                                                            },
                                                            expression:
                                                              "{\n                    content: `${data.item.option_desc[0]}`,\n                    placement: 'right-center',\n                    classes: ['light'],\n                    delay: {\n                      show: 500,\n                      hide: 300\n                    },\n                    offset: '5'\n                  }",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "fa fa-info-circle text-info mx-1",
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                { key: `field-${index}` },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "font-light",
                                                      class: {
                                                        "font-semi-bold":
                                                          data.item.option
                                                            .toLowerCase()
                                                            .includes("total"),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatPayment"
                                                            )(
                                                              data.item[
                                                                field.key
                                                              ]
                                                            )
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  data.item[`${field.key}_desc`]
                                                    ? _c("i", {
                                                        directives: [
                                                          {
                                                            name: "tooltip",
                                                            rawName:
                                                              "v-tooltip",
                                                            value: {
                                                              content:
                                                                data.item[
                                                                  `${field.key}_desc`
                                                                ],
                                                              placement:
                                                                "bottom-center",
                                                              classes: [
                                                                "light",
                                                              ],
                                                              delay: {
                                                                show: 500,
                                                                hide: 300,
                                                              },
                                                              offset: "5",
                                                            },
                                                            expression:
                                                              "{\n                    content: data.item[`${field.key}_desc`],\n                    placement: 'bottom-center',\n                    classes: ['light'],\n                    delay: {\n                      show: 500,\n                      hide: 300\n                    },\n                    offset: '5'\n                  }",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "fa fa-info-circle text-info mx-1",
                                                      })
                                                    : _vm._e(),
                                                ]
                                              ),
                                        ]
                                      },
                                    }
                                  }
                                ),
                              ],
                              null,
                              true
                            ),
                          })
                        : _c("div", { staticClass: "control-loader" }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }