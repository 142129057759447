<template>
  <b-row>
    <b-colxx xl="4" lg="12" sm="12" md="12" class="mb-4">
      <b-card class="dashboard-link-list" no-body>
        <h5 class="m-4">Overall Profitability</h5>
        <div class="mx-3 my-0">
          <base-chart
            :key="`profitability-${reloadChart}-${reloadProfitabilityChart}`"
            :loading="loading"
            :data="profitabilityChartData"
            :options="profitabilityChartOptions"
            shadow
          ></base-chart>
        </div>
        <div class="ml-5 mr-4">
          <overall-result-chart-legend
            v-if="profitabilityChartDataSet && profitabilityChartDataSet.length > 0"
            key="profit-chart-legend"
            :formatType="2"
            :options="profitabilityChartDataSet"
            @optionClicked="onProfitabilityOptionClick"
          ></overall-result-chart-legend>
        </div>
      </b-card>
    </b-colxx>
    <b-colxx xl="4" lg="12" sm="12" md="12" class="mb-4">
      <b-card class="dashboard-link-list" no-body>
        <h5 class="m-4">Overall Volume</h5>
        <div class="mx-3 my-0">
          <base-chart
            :key="`volume-${reloadChart}-${reloadVolumeChart}`"
            :loading="loading"
            :data="volumeChartData"
            :options="volumeChartOptions"
            shadow
          ></base-chart>
        </div>
        <div class="ml-5 mr-4">
          <overall-result-chart-legend
            v-if="volumeChartDataSet && volumeChartDataSet.length > 0"
            key="volume-chart-legend"
            :formatType="1"
            :options="volumeChartDataSet"
            @optionClicked="onVolumeOptionClick"
          ></overall-result-chart-legend>
        </div>
      </b-card>
    </b-colxx>
    <b-colxx xl="4" lg="12" sm="12" md="12" class="mb-4">
      <b-card class="dashboard-link-list" no-body>
        <h5 class="m-4">Overall Accuracy</h5>
        <div class="mx-3 my-0">
          <base-chart
            :key="`accuracy-${reloadChart}-${reloadAccuracyChart}`"
            :loading="loading"
            :data="accuracyChartData"
            :options="accuracyChartOptions"
            shadow
          ></base-chart>
        </div>
        <div class="ml-5 mr-4">
          <overall-result-chart-legend
            v-if="accuracyChartDataSet && accuracyChartDataSet.length > 0"
            key="accuracy-chart-legend"
            :formatType="3"
            :options="accuracyChartDataSet"
            @optionClicked="onAccuracyOptionClick"
          ></overall-result-chart-legend>
        </div>
      </b-card>
    </b-colxx>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseChart from './BaseChart.vue'
import OverallResultChartLegend from './OverallResultChartLegend.vue'
import { profitabilityChartOptions, volumeChartOptions, accuracyChartOptions } from './config'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseChart,
    OverallResultChartLegend
  },
  data() {
    return {
      reloadChart: 0,
      profitabilityChartOptions,
      volumeChartOptions,
      accuracyChartOptions,
      profitabilityChartData: null,
      profitabilityChartDataSet: [],
      volumeChartData: null,
      volumeChartDataSet: [],
      accuracyChartData: null,
      accuracyChartDataSet: [],
      reloadProfitabilityChart: 0,
      reloadVolumeChart: 0,
      reloadAccuracyChart: 0
    }
  },
  methods: {
    async getProfitabilityDataSet() {
      let self = this
      self.profitabilityChartData = await _.cloneDeep(
        JSON.parse(JSON.stringify(self.overallProfitability))
      )
      if (_.has(self.profitabilityChartData, 'datasets')) {
        self.profitabilityChartDataSet = self.profitabilityChartData.datasets
      }
    },
    async getVolumeDataSet() {
      let self = this
      self.volumeChartData = await _.cloneDeep(JSON.parse(JSON.stringify(self.overallVolume)))
      if (_.has(self.volumeChartData, 'datasets')) {
        self.volumeChartDataSet = self.volumeChartData.datasets
      }
    },
    async getAccuracyDataSet() {
      let self = this
      self.accuracyChartData = await _.cloneDeep(JSON.parse(JSON.stringify(self.overallAccuracy)))
      if (_.has(self.accuracyChartData, 'datasets')) {
        self.accuracyChartDataSet = self.accuracyChartData.datasets
      }
    },
    onProfitabilityOptionClick(index) {
      let self = this
      self.profitabilityChartData.datasets[index].hidden =
        !self.profitabilityChartData.datasets[index].hidden
      this.reloadProfitabilityChart += 1
    },
    onVolumeOptionClick(index) {
      let self = this
      self.volumeChartData.datasets[index].hidden = !self.volumeChartData.datasets[index].hidden
      this.reloadVolumeChart += 1
    },
    onAccuracyOptionClick(index) {
      let self = this
      self.accuracyChartData.datasets[index].hidden = !self.accuracyChartData.datasets[index].hidden
      this.reloadAccuracyChart += 1
    }
  },
  computed: {
    ...mapGetters(['overallProfitability', 'overallVolume', 'overallAccuracy']),
    isLoading() {
      return this.loading
    }
  },
  watch: {
    isLoading() {
      this.reloadChart += 1
    },
    overallProfitability(newVal) {
      if (newVal) {
        this.getProfitabilityDataSet()
        this.reloadProfitabilityChart += 1
      }
    },
    overallVolume(newVal) {
      if (newVal) {
        this.getVolumeDataSet()
        this.reloadVolumeChart += 1
      }
    },
    overallAccuracy(newVal) {
      if (newVal) {
        this.getAccuracyDataSet()
        this.reloadAccuracyChart += 1
      }
    }
  }
}
</script>
