<template>
  <div class="game-settings-control">
    <b-form @submit.prevent="onGameSettingsSubmit">
      <div class="d-flex">
        <div class="align-self-center mr-4">
          <label class="settings-label pl-0 mb-2 col-12">Require approval code?</label>
          <div class="d-flex">
            <b-form-radio
              v-model="approvalCodeValidation"
              name="ac-radios"
              :value="1"
              :disabled="loadingGameSettings"
              class="align-self-center d-inline-block"
            >
              <span>Yes</span>
            </b-form-radio>
            <b-form-radio
              v-model="approvalCodeValidation"
              name="ac-radios"
              :value="0"
              :disabled="loadingGameSettings"
              class="align-self-center d-inline-block mx-4"
            >
              <span>No</span>
            </b-form-radio>
          </div>
        </div>
        <div class="align-self-center">
          <label class="settings-label pl-0 mb-2 col-12">Work Complexity</label>
          <div class="d-flex">
            <b-form-radio
              v-model="workComplexity"
              name="wc-radios"
              :value="2"
              :disabled="loadingGameSettings"
              class="align-self-center d-inline-block"
            >
              <span>Letter</span>
            </b-form-radio>
            <b-form-radio
              v-model="workComplexity"
              name="wc-radios"
              :value="1"
              :disabled="loadingGameSettings"
              class="align-self-center d-inline-block mx-4"
            >
              <span>Table</span>
            </b-form-radio>
          </div>
        </div>
      </div>
      <div class="d-flex action-button">
        <div class="align-self-center ml-auto">
          <processing-button
            key="btn-work"
            variant="primary"
            size="sm"
            label="Save"
            class="align-self-center btn-shadow ml-auto"
            :processing="processing"
            :disabled="!gameSettingsChanged"
          ></processing-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
export default {
  props: {
    teamId: {
      type: [Number, String],
      default: null
    }
  },
  components: {
    ProcessingButton
  },
  data() {
    return {
      processing: false,
      workComplexity: null,
      approvalCodeValidation: null,
      gameSettingsChanged: false,
      loadingGameSettings: true
    }
  },
  methods: {
    ...mapActions(['fetchTeamGameSettings', 'updateTeamGameSettings']),
    async getTeamGameSettings() {
      let self = this
      self.loadingGameSettings = true
      await self.fetchTeamGameSettings(self.teamId)
      self.workComplexity = self.teamWorkComplexity
      self.approvalCodeValidation = self.teamApprovalCodeValidation
      self.loadingGameSettings = false
    },
    async onGameSettingsSubmit() {
      let self = this
      let payload = {
        id: self.teamId,
        work_complexity: self.workComplexity,
        approval_code: self.approvalCodeValidation
      }

      self.processing = true
      self.loadingGameSettings = true
      await self.updateTeamGameSettings(payload)
      self.processing = false
      self.loadingGameSettings = false
      self.gameSettingsChanged = false
    },
    resetData() {
      let self = this
      self.workComplexity = null
      self.approvalCodeValidation = null
      self.gameSettingsChanged = false
    }
  },
  computed: {
    ...mapGetters(['teamWorkComplexity', 'teamApprovalCodeValidation'])
  },
  created() {
    let self = this
    if (self.teamId) {
      self.getTeamGameSettings()
    }
  },
  watch: {
    teamId(newVal) {
      if (newVal) {
        let self = this
        self.resetData()
        self.getTeamGameSettings()
      }
    },
    workComplexity(newVal, oldVal) {
      let self = this
      if (oldVal !== null && !self.loadingGameSettings) {
        self.gameSettingsChanged = true
      }
    },
    approvalCodeValidation(newVal, oldVal) {
      let self = this
      if (oldVal !== null && !self.loadingGameSettings) {
        self.gameSettingsChanged = true
      }
    }
  }
}
</script>
