var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "icon-toggle d-flex" },
    _vm._l(_vm.toggleItems, function (item, index) {
      return _c("i", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: {
              content: item.title,
              placement: "bottom-center",
              classes: ["light"],
              delay: {
                show: 500,
                hide: 300,
              },
              offset: "5",
            },
            expression:
              "{\n      content: item.title,\n      placement: 'bottom-center',\n      classes: ['light'],\n      delay: {\n        show: 500,\n        hide: 300\n      },\n      offset: '5'\n    }",
          },
        ],
        key: `item-${index}`,
        staticClass: "align-self-center ml-2",
        class: [
          item.icon,
          item.id === _vm.selectedToggleId ? "text-primary" : "text-muted",
        ],
        on: {
          click: function ($event) {
            return _vm.onToggleSwitch(item.id)
          },
        },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }