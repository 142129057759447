var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "work-allocations-control" },
    [
      _c(
        "b-row",
        _vm._l(_vm.allWorkAllocations, function (work, index) {
          return _c(
            "b-col",
            {
              key: `work-${index}`,
              staticClass: "my-3 text-center",
              attrs: { cols: "3" },
            },
            [
              _c("work-allocation-input", {
                key: `work-allocation-input-${index}`,
                attrs: {
                  avatar: work.avatar,
                  userName: work.userName,
                  quickAssigned: _vm.allQuickAssigned,
                },
                model: {
                  value: work.workAllocations,
                  callback: function ($$v) {
                    _vm.$set(work, "workAllocations", $$v)
                  },
                  expression: "work.workAllocations",
                },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }