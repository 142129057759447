var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "work-allocations-table" },
    [
      _c(
        "b-card",
        { staticClass: "table-card" },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onWorkAllocationSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "header d-flex mb-2" },
                [
                  _c("h4", { staticClass: "m-0 align-self-center" }, [
                    _vm._v("Work Allocations"),
                  ]),
                  _vm._v(" "),
                  _c("base-single-selection", {
                    staticClass: "ml-2 align-self-center",
                    attrs: {
                      loading: _vm.loading,
                      itemKey: "id",
                      itemName: "week_name",
                      options: _vm.teamWeeks,
                      placeholder: "Select week",
                      badge: "",
                    },
                    model: {
                      value: _vm.selectedWeek,
                      callback: function ($$v) {
                        _vm.selectedWeek = $$v
                      },
                      expression: "selectedWeek",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ml-auto d-flex align-self-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "align-self-center mr-3" },
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "align-self-center",
                              attrs: {
                                variant: "empty",
                                "toggle-class": "p-0",
                                "no-caret": "",
                                right: "",
                                "menu-class": "mt-2 btn-shadow",
                                disabled:
                                  _vm.loadingWorkAllocations ||
                                  _vm.selectedWeek.status_id === 3,
                              },
                            },
                            [
                              _c("template", { slot: "button-content" }, [
                                _c(
                                  "div",
                                  {
                                    key: _vm.quickPolicyPreference,
                                    staticClass:
                                      "policy-icon animate__animated animate__bounceIn",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.getPolicyPreference(
                                          _vm.quickPolicyPreference
                                        ),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.policyPreferences,
                                function (policy, index) {
                                  return _c(
                                    "b-dropdown-item",
                                    {
                                      key: `policy-option-${index}`,
                                      staticClass: "d-flex",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onQuickPolicyOptionClick(
                                            policy
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "d-flex" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content-img-bg align-self-center mr-2",
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: policy.img,
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "align-self-center" },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "mx-1 mb-1" },
                                              [_vm._v(_vm._s(policy.label))]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "align-self-center" },
                        [
                          _c("quick-work-allocation-selector", {
                            staticClass: "quick-allocations align-self-center",
                            attrs: {
                              disabled:
                                _vm.loadingWorkAllocations ||
                                _vm.selectedWeek.status_id === 3,
                            },
                            model: {
                              value: _vm.quickWorkAllcation,
                              callback: function ($$v) {
                                _vm.quickWorkAllcation = $$v
                              },
                              expression: "quickWorkAllcation",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: _vm.loading || _vm.loadingWorkAllocations,
                    rounded: "sm",
                    "bg-color": "white",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "overlay",
                      fn: function () {
                        return [
                          _c("running-loader", { attrs: { loading: "" } }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("b-table", {
                    staticClass: "mb-0",
                    attrs: {
                      fields: _vm.workAllocationsTable.fields,
                      items: _vm.workAllocationsTableData,
                      hover: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(user_name)",
                        fn: function (data) {
                          return [
                            _c("b-avatar", {
                              attrs: { src: data.item.avatar, size: "35" },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v(_vm._s(data.item.user_name)),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "cell(allocated)",
                        fn: function (data) {
                          return [
                            _c(
                              "transition",
                              {
                                attrs: {
                                  "enter-active-class":
                                    _vm.setPendingAllocationsTransition(
                                      data.item.allocated,
                                      data.item.add,
                                      data.item.remove,
                                      data.item
                                    ).enter,
                                  "leave-active-class":
                                    _vm.setPendingAllocationsTransition(
                                      data.item.allocated,
                                      data.item.add,
                                      data.item.remove,
                                      data.item
                                    ).leave,
                                },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    key: `lodged-${data.item.allocated}-${data.item.add}-${data.item.remove}`,
                                    staticClass:
                                      "m-0 float-text position-absolute",
                                  },
                                  [
                                    _c("b-avatar", {
                                      staticClass: "mr-1",
                                      attrs: {
                                        variant:
                                          _vm.setPendingAllocationsVariant(
                                            data.item.allocated,
                                            data.item.add,
                                            data.item.remove
                                          ),
                                        text: _vm.setPendingAllocationsText(
                                          data.item.allocated,
                                          data.item.add,
                                          data.item.remove
                                        ),
                                        size: "30",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "fa",
                                      class: _vm.setPendingAllocationsTrend(
                                        data.item.allocated,
                                        data.item.add,
                                        data.item.remove
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(lodged)",
                        fn: function (data) {
                          return [
                            _c(
                              "transition",
                              {
                                attrs: {
                                  "enter-active-class":
                                    "animate__animated animate__fadeInUp",
                                  "leave-active-class":
                                    "animate__animated animate__fadeOutUp",
                                },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    key: `lodged-${data.item.lodged}`,
                                    staticClass:
                                      "m-0 float-text position-absolute",
                                  },
                                  [
                                    _c("b-avatar", {
                                      attrs: {
                                        variant: "white",
                                        text:
                                          data.item.lodged === 0
                                            ? "0"
                                            : data.item.lodged.toString(),
                                        size: "30",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(remaining)",
                        fn: function (data) {
                          return [
                            _c(
                              "transition",
                              {
                                attrs: {
                                  "enter-active-class":
                                    _vm.setPendingAllocationsTransition(
                                      data.item.remaining,
                                      data.item.add,
                                      data.item.remove,
                                      data.item
                                    ).enter,
                                  "leave-active-class":
                                    _vm.setPendingAllocationsTransition(
                                      data.item.remaining,
                                      data.item.add,
                                      data.item.remove,
                                      data.item
                                    ).leave,
                                },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    key: `remaining-${data.item.remaining}-${data.item.add}-${data.item.remove}`,
                                    staticClass:
                                      "m-0 float-text position-absolute",
                                  },
                                  [
                                    _c("b-avatar", {
                                      staticClass: "mr-1",
                                      attrs: {
                                        variant:
                                          _vm.setPendingAllocationsVariant(
                                            data.item.remaining,
                                            data.item.add,
                                            data.item.remove
                                          ),
                                        text: _vm.setPendingAllocationsText(
                                          data.item.remaining,
                                          data.item.add,
                                          data.item.remove
                                        ),
                                        size: "30",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "fa",
                                      class: _vm.setPendingAllocationsTrend(
                                        data.item.remaining,
                                        data.item.add,
                                        data.item.remove
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(add)",
                        fn: function (data) {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-center" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex policy-input-wrapper",
                                    class: {
                                      disabled:
                                        data.item.removeTouch ||
                                        _vm.selectedWeek.status_id === 3,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "policy-icon-wrapper" },
                                      [
                                        _c(
                                          "b-dropdown",
                                          {
                                            attrs: {
                                              variant: "empty",
                                              "toggle-class": "p-0",
                                              "menu-class": "mt-2 btn-shadow",
                                              boundary: "window",
                                              "no-caret": "",
                                              right: "",
                                              disabled:
                                                _vm.loadingWorkAllocations ||
                                                _vm.selectedWeek.status_id ===
                                                  3,
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "button-content" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    key: `user-policy-${data.item.user_id}-${data.item.policy_preference}`,
                                                    staticClass:
                                                      "policy-icon animate__animated animate__bounceIn",
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm.getPolicyPreference(
                                                          data.item
                                                            .policy_preference
                                                        ),
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.policyPreferences,
                                              function (policy, index) {
                                                return _c(
                                                  "b-dropdown-item",
                                                  {
                                                    key: `policy-option-${index}`,
                                                    staticClass: "d-flex",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onPolicyOptionClick(
                                                          data.item,
                                                          policy
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "d-flex" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "content-img-bg align-self-center mr-2",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: policy.img,
                                                                alt: "",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "align-self-center",
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "mx-1 mb-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    policy.label
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("b-form-input", {
                                      staticClass:
                                        "px-2 py-1 work-input text-center align-self-center",
                                      attrs: {
                                        type: "number",
                                        min: "0",
                                        oninput: "validity.valid||(value='');",
                                        disabled:
                                          data.item.removeTouch ||
                                          _vm.selectedWeek.status_id === 3,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.onAllocationsInput(
                                            data.item,
                                            1
                                          )
                                        },
                                        focus: function ($event) {
                                          return $event.target.select()
                                        },
                                      },
                                      model: {
                                        value: data.item.add,
                                        callback: function ($$v) {
                                          _vm.$set(data.item, "add", $$v)
                                        },
                                        expression: "data.item.add",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content: data.item.addError,
                                        placement: "bottom",
                                        classes: ["light"],
                                        offset: "5",
                                      },
                                      expression:
                                        "{\n                  content: data.item.addError,\n                  placement: 'bottom',\n                  classes: ['light'],\n                  offset: '5'\n                }",
                                    },
                                  ],
                                  key: `add-error-check-${data.item.id}-${data.item.addError}`,
                                  staticClass:
                                    "fa fa-exclamation-circle work-input-error align-self-center ml-2 animate__animated animate__bounceIn",
                                  class: data.item.addError
                                    ? "text-danger"
                                    : "text-light",
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(remove)",
                        fn: function (data) {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-center" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex policy-input-wrapper",
                                    class: {
                                      disabled:
                                        data.item.addTouch ||
                                        _vm.selectedWeek.status_id === 3,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "policy-icon-wrapper" },
                                      [
                                        _c(
                                          "b-dropdown",
                                          {
                                            attrs: {
                                              variant: "empty",
                                              "toggle-class":
                                                "p-0 disabled-policy",
                                              "no-caret": "",
                                              disabled: "",
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "button-content" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "policy-icon",
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm.getPolicyPreference(
                                                          "random"
                                                        ),
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("b-form-input", {
                                      staticClass:
                                        "px-2 py-1 work-input text-center align-self-center",
                                      attrs: {
                                        type: "number",
                                        min: "0",
                                        oninput: "validity.valid||(value='');",
                                        disabled:
                                          data.item.addTouch ||
                                          _vm.selectedWeek.status_id === 3,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.onAllocationsInput(
                                            data.item,
                                            2
                                          )
                                        },
                                        focus: function ($event) {
                                          return $event.target.select()
                                        },
                                      },
                                      model: {
                                        value: data.item.remove,
                                        callback: function ($$v) {
                                          _vm.$set(data.item, "remove", $$v)
                                        },
                                        expression: "data.item.remove",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content: data.item.removeError,
                                        placement: "bottom",
                                        classes: ["light"],
                                        offset: "5",
                                      },
                                      expression:
                                        "{\n                  content: data.item.removeError,\n                  placement: 'bottom',\n                  classes: ['light'],\n                  offset: '5'\n                }",
                                    },
                                  ],
                                  key: `add-error-check-${data.item.id}-${data.item.removeError}`,
                                  staticClass:
                                    "fa fa-exclamation-circle work-input-error align-self-center ml-2 animate__animated animate__bounceIn",
                                  class: data.item.removeError
                                    ? "text-danger"
                                    : "text-light",
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.visibleRows,
                      callback: function ($$v) {
                        _vm.visibleRows = $$v
                      },
                      expression: "visibleRows",
                    },
                  }),
                  _vm._v(" "),
                  _c("b-table", {
                    staticClass: "total-table",
                    attrs: {
                      fields: _vm.workAllocationsTableFooter.fields,
                      items: _vm.workAllocationsTableFooter.data,
                      hover: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(allocated)",
                        fn: function (data) {
                          return [
                            _c(
                              "h6",
                              { staticClass: "m-0" },
                              [
                                _c(
                                  "b-badge",
                                  {
                                    attrs: {
                                      pill: "",
                                      variant: "outline-info",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.totalAllocated))]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(lodged)",
                        fn: function (data) {
                          return [
                            _c(
                              "h6",
                              { staticClass: "m-0" },
                              [
                                _c(
                                  "b-badge",
                                  {
                                    attrs: {
                                      pill: "",
                                      variant: "outline-info",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.totalLodged))]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(remaining)",
                        fn: function (data) {
                          return [
                            _c(
                              "h6",
                              { staticClass: "m-0" },
                              [
                                _c(
                                  "b-badge",
                                  {
                                    attrs: {
                                      pill: "",
                                      variant: "outline-info",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.totalRemaining))]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex action-button" }, [
                _c(
                  "div",
                  { staticClass: "align-self-center ml-auto" },
                  [
                    _c("processing-button", {
                      key: "btn-work",
                      staticClass: "align-self-center btn-shadow ml-auto",
                      attrs: {
                        variant: "primary",
                        size: "sm",
                        label: "Save",
                        processing: _vm.processingWork,
                        disabled:
                          !_vm.hasEditedWork ||
                          _vm.selectedWeek.status_id === 3 ||
                          _vm.hasAllocationError,
                        error: _vm.hasAllocationError,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }