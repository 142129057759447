var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quick-work-allocation-selector" },
    [
      _c(
        "b-dropdown",
        {
          staticClass: "quick-allocations align-self-center",
          attrs: {
            right: "",
            variant: "empty",
            "toggle-class": "p-0",
            "no-caret": "",
            "menu-class": "quick-allocations-menu mt-2 btn-shadow",
            disabled: _vm.disabled,
          },
          scopedSlots: _vm._u([
            {
              key: "button-content",
              fn: function () {
                return [
                  _c("span", { staticClass: "numberCircle" }, [_vm._v("+")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "policy-icon animate__animated animate__bounceIn",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/assets/img/svg/controls/full-inbox-outline.svg",
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._l(_vm.quickAssignedOptions, function (option, index) {
            return _c(
              "b-dropdown-item",
              {
                key: `allocation-${index}`,
                staticClass: "d-flex",
                on: {
                  click: function ($event) {
                    return _vm.onQuickAllocationClick(option)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fa fa-book text-primary align-self-center",
                }),
                _vm._v(" x\n      "),
                _c("span", { staticClass: "align-self-center" }, [
                  _vm._v(_vm._s(option)),
                ]),
              ]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }